import { environment } from "src/environments/environment";
import { errorHandler } from "src/app/util/errorHandler";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { authData } from "src/app/util/auth";

@Component({
  selector: "app-payment-tranzgate",
  templateUrl: "./payment-tranzgate.component.html",
})
export class PaymentTranzgateComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}
  session = "";
  semester = "";
  sessions: any = [];
  notification: any = {};

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/payments/tranzgateSessions`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.sessions = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
      },
      error: (err) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.skeletonLoader = false;
      },
    });
  }

  tranzgateClientList() {
    if (!this.session) return;
    const encodedSession = encodeURIComponent(this.session);
    this.router.navigateByUrl(`/payments/tranzgate/${encodedSession}`);
  }
}
