<main class="flex flex-col h-screen max-h-screen bg-primary/5 overflow-hidden">
  <app-loading-indicator *ngIf="loading"></app-loading-indicator>
  <app-top-nav-bar></app-top-nav-bar>

  <section class="flex-grow h-full justify-between md:flex">
    <nav class="overflow-y-auto md:h-full">
      <app-navigation-drawer></app-navigation-drawer>
    </nav>

    <main class="h-full max-h-full flex-1 p-4 pb-52 overflow-y-auto md:pb-20">
      <app-student-info-header *ngIf="!notDashboard"></app-student-info-header>
      <ng-content></ng-content>
    </main>

    <aside
      class="bg-white border-l min-h-full w-80 overflow-y-auto hidden lg:block"
    >
      <app-pending-actions></app-pending-actions>
      <app-news></app-news>
    </aside>
  </section>

  <app-download-dialog
    *ngIf="downloader"
    [data]="downloader"
  ></app-download-dialog>
</main>
