<section
  role="dialog"
  aria-modal="true"
  aria-labelledby="modal-title"
  class="inset-0 z-10 fixed overflow-y-auto"
>
  <div class="flex flex-col h-full text-center p-4 items-center justify-center">
    <div
      aria-hidden="true"
      class="bg-gray-500 bg-opacity-75 inset-0 transition-opacity fixed"
    ></div>

    <div
      class="bg-white rounded-lg shadow-xl text-left w-full transform transition-all overflow-hidden sm:max-w-screen-sm sm:my-8"
    >
      <section class="p-4">
        <div class="text-center p-4">
          <div
            *ngIf="data?.loading"
            class="text-primary animate-spin text-4xl duration-[5000s] material-icons"
          >
            hourglass_top
          </div>

          <div
            *ngIf="!data?.loading"
            class="text-primary text-6xl material-icons"
          >
            file_download
          </div>

          <div class="pt-4">
            {{
              data?.loading
                ? "Processing. Please wait"
                : "Your document is available."
            }}
          </div>
        </div>

        <nav *ngIf="data?.url" class="grid pt-4 gap-4 grid-cols-2">
          <button
            (click)="refreshPage()"
            class="bg-white border border-error rounded font-medium text-error text-sm text-center py-2 px-4 block uppercase whitespace-nowrap md:px-8 hover:bg-error/90 hover:text-white"
          >
            Close
          </button>

          <a
            (click)="waitAndClose()"
            [href]="sanitizeUrl(data?.url)"
            [download]="data?.name || 'document.pdf'"
            class="bg-primary rounded font-medium text-white text-sm text-center py-2 px-4 block uppercase whitespace-nowrap md:px-8 hover:bg-primary/90"
          >
            Download Document
          </a>
        </nav>
      </section>
    </div>
  </div>
</section>
