<app-dashboard-layout [notDashboard]="true" [loading]="loading">
  <app-notification-alert [data]="notification"></app-notification-alert>

  <section class="bg-white border rounded-md p-4">

    <header class="text-lg mb-6 w-full pb-4 gap-8 md:text-xl">
      Update Your Passport
      <div class="text-sm py-2 text-gray-600">
        Allowed Types: PNG, JPG, JPEG
      </div>
      <div class="text-sm text-gray-600">Max Size: 2MB</div>
    </header>

    <div class="flex flex-col p-4 gap-4 items-center">
      <label style="background-image: url({{ payload.PassportData || studentPassport ?
          'data:image/png;base64,' + (payload.PassportData ||  studentPassport)
          : 'https://www.gravatar.com/avatar/?d=mp'
        }})"
        class="bg-cover bg-center bg-no-repeat border-white rounded-full cursor-pointer flex flex-col bg-blue-200 border-2 h-48 ring-primary ring-1 w-48 group justify-end items-end">
        <input type="file" class="hidden" accept=".png, .jpg, .jpeg" (change)="generatePreview($event)"
          [disabled]="_checks.PassportPhotoLocked || loading" />
        <div class="text-primary/50 text-5xl material-icons inline-block absolute group-hover:text-primary">
          add_a_photo
        </div>
      </label>

      <p class="text-sm text-gray-500">
        Click on the photo to upload a new picture
      </p>

      <app-button-primary (click)="updatePassport()" class="max-w-sm w-full"
        [disabled]="_checks.PassportPhotoLocked || loading">
        Update
      </app-button-primary>
    </div>
  </section>
</app-dashboard-layout>