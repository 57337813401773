import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
@Component({
  selector: "app-download-dialog",
  templateUrl: "./download-dialog.component.html",
})
export class DownloadDialogComponent implements OnInit {
  constructor(private domSanitizer: DomSanitizer) {}

  @Input() data: DownloadProp | null = null;

  ngOnInit(): void {}

  refreshPage() {
    window.location.reload();
  }

  waitAndClose() {
    setTimeout(() => {
      this.refreshPage();
    }, 100);
  }

  sanitizeUrl(rawData: any) {
    return this.domSanitizer.bypassSecurityTrustUrl(rawData);
  }
}

interface DownloadProp {
  url?: string;
  name?: string;
  loading?: boolean;
}
