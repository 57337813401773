<app-dashboard-layout
  [notDashboard]="true"
  [loading]="loading || skeletonLoader"
>
  <section class="bg-white border rounded-md p-4">
    <header class="border-b flex text-lg mb-6 w-full pb-4 gap-8 md:text-xl">
      Medical History
    </header>

    <div
      *ngIf="skeletonLoader"
      class="max-w-screen-md grid pb-4 gap-4 grid-cols-1 md:gap-8 md:grid-cols-2"
    >
      <div
        *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]"
        class="border rounded-md bg-gray-300 h-20 animate-pulse"
      ></div>
    </div>

    <form
      ngNativeValidate
      *ngIf="!skeletonLoader"
      id="biodata-medical-history"
      (ngSubmit)="updateMedicalHistory()"
      class="max-w-screen-md grid gap-8 grid-cols-1"
    >
      <app-notification-alert [data]="notification"></app-notification-alert>

      <section class="">
        <div class="font-medium text-grey-darker text-sm pb-2 block">
          What is your health status
        </div>

        <div
          class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
        >
          <select
            required
            name="HealthStatus"
            [(ngModel)]="payload.HealthStatus"
            class="w-full p-1 focus:outline-none"
          >
            <option disabled value="" defaultSelected>Select option</option>
            <option *ngFor="let option of ['Healthy', 'Good', 'Fine', 'Bad']">
              {{ option }}
            </option>
          </select>
        </div>
      </section>

      <section class="grid gap-8 grid-cols-1 md:gap-8 md:grid-cols-2">
        <label class="block">
          <div class="font-medium text-grey-darker text-sm pb-2">
            Do you have any specific disabilities? *
          </div>

          <div
            class="border border-primary-lighter rounded flex text-sm w-full py-2 px-3 text-gray-700 justify-between items-center appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <div>{{ payload.SpecificDisability }}</div>
            <input
              type="checkbox"
              name="SpecificDisability"
              (change)="toggleCheckBox('SpecificDisability')"
            />
          </div>
        </label>

        <label class="block">
          <div class="font-medium text-grey-darker text-sm pb-2">
            Do you require any routine medications? *
          </div>
          <div
            class="border border-primary-lighter rounded flex text-sm w-full py-2 px-3 text-gray-700 justify-between items-center appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <div>{{ payload.MedicationRequired }}</div>
            <input
              type="checkbox"
              name="MedicationRequired"
              (change)="toggleCheckBox('MedicationRequired')"
            />
          </div>
        </label>
      </section>

      <label class="block">
        <div class="font-medium text-grey-darker text-sm pb-2">
          Have you ever being hospitalized? *
        </div>
        <div
          class="border border-primary-lighter rounded flex text-sm w-full py-2 px-3 text-gray-700 justify-between items-center appearance-none focus:outline-none focus-within:border-primary-light"
        >
          <div>{{ payload.Hospitalized }}</div>
          <input
            type="checkbox"
            name="Hospitalized"
            (change)="toggleCheckBox('Hospitalized')"
          />
        </div>
      </label>

      <section *ngIf="payload.Hospitalized">
        <div class="font-medium text-grey-darker text-sm pb-2 block">
          Select any reason(s) for which you have been admitted in a hospital
        </div>

        <div class="grid gap-8 grid-cols-1 md:grid-cols-2">
          <div
            *ngFor="let i of [0, 1, 2]"
            class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <select
              required
              [name]="'IllnessList' + i"
              [(ngModel)]="payload.IllnessList[i]"
              class="w-full p-1 focus:outline-none"
            >
              <option disabled value="">Select option</option>
              <option *ngFor="let option of illnesses">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </section>

      <section>
        <div class="font-medium text-grey-darker text-sm pb-2 block">
          Select any current or past medical condition(s)
        </div>

        <div class="grid gap-8 grid-cols-1 md:grid-cols-2">
          <div
            *ngFor="let i of [0, 1, 2]"
            class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <select
              required
              [name]="'MedicalList' + i"
              [(ngModel)]="payload.MedicalList[i]"
              class="w-full p-1 focus:outline-none"
            >
              <option disabled value="">Select option</option>
              <option *ngFor="let option of medicalConditions">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </section>

      <section>
        <div class="font-medium text-grey-darker text-sm pb-2 block">
          Other Medical Conditions
        </div>
        <input
          type="text"
          name="OtherMedicalConditions"
          placeholder="Other Medical Conditions"
          [(ngModel)]="payload.OtherMedicalConditions"
          class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
        />
      </section>

      <section
        class="max-w-screen-md grid gap-4 grid-cols-1 md:gap-8 md:grid-cols-2"
      >
        <label class="block">
          <div class="font-medium text-grey-darker text-sm pb-2">
            Do you smoke? *
          </div>

          <div
            class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <select
              required
              name="DoYouSmoke"
              [(ngModel)]="payload.DoYouSmoke"
              class="w-full p-1 focus:outline-none"
            >
              <option disabled value="">Select option</option>
              <option
                *ngFor="
                  let option of [
                    'No',
                    'YES: 1 stick/day',
                    'YES: 1-5 stick/day',
                    'YES: 6-10 sticks/day',
                    'YES: more than 10 sticks/day'
                  ]
                "
              >
                {{ option }}
              </option>
            </select>
          </div>
        </label>

        <label class="block">
          <div class="font-medium text-grey-darker text-sm pb-2">
            Do you take alcohol? *
          </div>

          <div
            class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <select
              required
              name="DoYouTakeAlcohol"
              [(ngModel)]="payload.DoYouTakeAlcohol"
              class="w-full p-1 focus:outline-none"
            >
              <option disabled value="">Select option</option>
              <option
                *ngFor="
                  let option of ['No', 'Occasionally', 'Regularly', 'Heavily']
                "
              >
                {{ option }}
              </option>
            </select>
          </div>
        </label>
      </section>

      <section>
        <div class="font-medium text-grey-darker text-sm pb-2 block">
          How often do you exercise?
        </div>

        <div
          class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
        >
          <select
            required
            name="Sport"
            [(ngModel)]="payload.Sport"
            class="w-full p-1 focus:outline-none"
          >
            <option disabled value="">Select option</option>
            <option *ngFor="let option of sports">
              {{ option }}
            </option>
          </select>
        </div>
      </section>

      <section>
        <div class="font-medium text-grey-darker text-sm pb-2 block">
          Do you have a family history of any of these conditions?
        </div>

        <div
          class="border border-primary-lighter rounded w-full grid p-4 text-gray-700 gap-4 grid-cols-1 appearance-none md:grid-cols-2 focus:outline-none focus-within:border-primary-light"
        >
          <label
            *ngFor="let condition of getArray(payload.FamilyHistoryDTO)"
            class=""
          >
            <input
              class="mr-2"
              type="checkbox"
              [name]="condition"
              [(ngModel)]="payload.FamilyHistoryDTO[condition]"
            />
            {{ condition.replace("FamilyHistoryof", "") }}</label
          >
        </div>
      </section>

      <section>
        <div class="font-medium text-grey-darker text-sm pb-2 block">
          Other Family Illness History:
        </div>
        <input
          type="text"
          name="OtherillnessHistory"
          placeholder="Other illness history"
          [(ngModel)]="payload.OtherillnessHistory"
          class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
        />
      </section>

      <section>
        <div class="font-medium text-grey-darker text-sm pb-2 block">
          Select the vaccines you have received
        </div>

        <div
          class="border border-primary-lighter rounded flex text-sm w-full grid p-4 py-2 px-3 text-gray-700 gap-4 grid-cols-1 justify-between items-center appearance-none md:grid-cols-2 focus:outline-none focus-within:border-primary-light"
        >
          <label *ngFor="let condition of getArray(payload.VaccineDTO)">
            <input
              class="mr-2"
              type="checkbox"
              [name]="condition"
              [(ngModel)]="payload.VaccineDTO[condition]"
            />
            {{ condition.replace("Vaccine", "") }}
          </label>
        </div>
      </section>

      <nav class="flex flex-col py-6 gap-4 justify-center">
        <app-button-primary
          type="submit"
          [disabled]="loading"
          class="mx-auto max-w-screen-sm w-full"
        >
          UPDATE MEDICAL HISTORY
        </app-button-primary>

        <app-notification-alert [data]="notification"></app-notification-alert>
      </nav>
    </form>
  </section>
</app-dashboard-layout>
