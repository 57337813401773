import { Component, OnInit } from "@angular/core";
import { authData, saveAuth } from "src/app/util/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
})
export class NewsComponent implements OnInit {
  constructor(private httpClient: HttpClient) {}

  _auth = authData();
  news: any[] = authData("_news") || [];

  skeletonLoader = false;
  notification: any = { message: "notification?.message", error: true };

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._auth.Token,
    }),
  };

  ngOnInit(): void {
    const news = authData("_news");
    if (news) return;

    this.skeletonLoader = true;
    const baseUrl = `${environment.BASE_URL}/users/news`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        saveAuth(res.Data, "_news");
        this.news = res.Data;
      },
      error: (err) => saveAuth([], "_news"),
      complete: () => {
        this.skeletonLoader = false;
      },
    });
  }
}
