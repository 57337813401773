<app-auth-layout [loading]="loading">
  <form (ngSubmit)="login()" class="flex flex-col h-full p-8 md:p-12">
    <h1
      class="font-bold py- text-center text-lg pb-4 uppercase md:text-xl md:pb-8 lg:text-2xl"
    >
      Log in
    </h1>

    <app-notification-alert [data]="notification"></app-notification-alert>

    <div class="py-4">
      <label
        class="font-bold text-grey-darker text-sm mb-2 block"
        htmlFor="email"
      >
        Matric/Application number
      </label>
      <input
        [(ngModel)]="payload.MatricNo"
        class="border rounded bg-gray-50 text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
        id="email"
        type="text"
        required
        placeholder="Matric/Application number"
      />
      <div *ngIf="errors.MatricNo" class="text-xs text-error-dark italic">
        Please enter your Matric or Application number
      </div>
    </div>

    <div class="pb-4">
      <label
        class="font-bold text-grey-darker text-sm mb-2 block"
        htmlFor="password"
      >
        Password
      </label>
      <input
        [(ngModel)]="payload.Password"
        class="border rounded bg-gray-50 text-sm mb-3 w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
        placeholder="******************"
        id="password"
        type="password"
        required
      />
      <div *ngIf="errors.Password" class="text-xs text-red-500 italic">
        Please enter a password.
      </div>
    </div>

    <div class="text-right">
      <a
        routerLink="/forgot-password"
        class="font-bold text-sm text-primary inline-block align-baseline hover:text-primary-dark hover:underline"
      >
        Forgot Password?
      </a>
    </div>

    <div class="py-4">
      <app-button-primary type="submit" [disabled]="loading" class="w-full">
        Sign In
      </app-button-primary>
    </div>
  </form>
</app-auth-layout>
