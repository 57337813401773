import { HttpClient } from "@angular/common/http";
import { errorHandler } from "src/app/util/errorHandler";
import { authData } from "src/app/util/auth";
import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-accommodation-application-rooms",
  templateUrl: "./accommodation-application-rooms.component.html",
})
export class AccommodationApplicationRoomsComponent implements OnInit {
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {}

  hallName = "";
  floors: any = [];

  loading = false;
  refreshing = true;
  notification = {};

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.refreshing = true;

      const baseUrl = `${environment.BASE_URL}/accomodation/accomodationRooms?hallId=${params.id}`;
      this.httpClient
        .get(baseUrl, this.httpOptions)
        .subscribe({
          next: (res: any) => {
            if (res.Success) {
              this.hallName = res.Data.HallOfResidenceId;
              const { FloorDTO } = res.Data;
              const floors: any = [...new Array(FloorDTO.length)];

              FloorDTO.forEach((floor: any, i = 0) => {
                floors[i] = {
                  ordinal: floor.Ordinal,
                  rooms: {},
                };

                floor.Rooms.forEach((space: any) => {
                  const { X, Y } = space.Location;
                  if (!floors[i].rooms[`${X}`])
                    floors[i].rooms[`${X}`] = { 0: {}, 1: {} };
                  floors[i].rooms[`${X}`][Y > 1 ? 1 : 0] = space;
                });
              });

              this.floors = floors;
            } else {
              this.notification = errorHandler(res);
            }
          },
          error: (err: any) => {
            this.notification = errorHandler(err);
          },
        })
        .add(() => {
          this.refreshing = false;
        });
    });
  }

  getRooms(floor: any, i: any): any {
    // console.log(floor[0]);
    // return Object.keys(floor[0]);
  }

  getSpace(spaces: any): any {
    return Object.values(spaces);
  }

  getArray(values: any): any {
    return Object.values(values);
  }

  applyForRoom() {
    // this.router.navigateByUrl(`/accommodation/application/${this.hall}`);
  }
}
