import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "./../../../../environments/environment";

@Component({
  selector: "app-bio-data-basic",
  templateUrl: "./bio-data-basic.component.html",
})
export class BioDataBasicComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  loading = false;
  payload: any = {};
  req: any = {};
  notification = {};
  skeletonLoader = true;
  status: boolean = false;
  verified = false;
  localGovts: any = [];
  states: any = [];
  selectedState = "";
  selectedLocalGovt = "";

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  DTOs = {
    Religion: ["Christianity", "Islam", "Other"],
    NameTitle: ["Mr", "Miss", "Mrs", "Dr", "Prof"],
    MaritalStatus: ["Single", "Married", "Divorced", "Widowed"],
  };

  ngOnInit(): void {
    const { AlternateEmailVerified, ProgramRequireEmailVerification } =
      authData("_checks");
    if (!ProgramRequireEmailVerification || AlternateEmailVerified) {
      this.verified = true;
    }

    const baseUrl = `${environment.BASE_URL}/bioData/bioDataDetails`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        this.skeletonLoader = false;
        this.payload = res.Data || {};
        if (!this.payload.StateOfOrigin) this.getStates();
        else this.getLocalGovts(this.payload.StateId);
      },
      (err) => {
        this.skeletonLoader = false;
      }
    );
  }

  updateBiodata() {
    this.loading = true;
    this.notification = {};
    if (!this.payload.LocalGovernmentArea) {
      if (!this.selectedLocalGovt) {
        this.notification = {
          error: true,
          message: "Local Government is required",
        };
        this.loading = false;
        return;
      }
      this.payload.LocalGovernmentArea = this.selectedLocalGovt;
    }
    if (!this.payload.StateOfOrigin) {
      if (!this.selectedState) {
        this.notification = {
          error: true,
          message: "State is required",
        };
        this.loading = false;
        return;
      }
      this.payload.StateOfOrigin = this.selectedState;
    }

    const baseUrl = `${environment.BASE_URL}/biodata/saveBiodata`;
    this.httpClient.put(baseUrl, this.payload, this.httpOptions).subscribe(
      (res) => {
        this.router.navigateByUrl("/bio-data#Biodata+information+updated");
      },
      (err) => {
        this.loading = false;
        this.notification = {
          error: true,
          message:
            err?.error?.Message || "An error occurred. Please, try again",
        };
      }
    );
  }

  verifyEmail() {
    this.req = {
      Email: this.payload.AlternateEmail,
      Url: `${environment.FRONTEND_BASE_URL}/verify`,
    };
    const baseUrl = `${environment.BASE_URL}/biodata/sendverificationmail`;
    this.httpClient.post(baseUrl, this.req, this.httpOptions).subscribe(
      (res) => {
        this.router.navigateByUrl(
          "/bio-data#Email+verification+link+has+been+sent+to+your+email"
        );
      },
      (err) => {
        this.loading = false;
        this.notification = {
          error: true,
          message:
            err?.error?.Message || "An error occurred. Please, try again",
        };
      }
    );
  }
  getStates() {
    this.skeletonLoader = true;
    const baseUrl = `${environment.BASE_URL}/biodata/states`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        this.skeletonLoader = false;
        this.states = res.Data || [];
      },
      (err) => {
        this.skeletonLoader = false;
      }
    );
  }

  getLocalGovts(stateId: string) {
    this.loading = true;
    const baseUrl = `${environment.BASE_URL}/biodata/localgovt?stateId=${stateId}`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        this.loading = false;
        this.localGovts = res.Data || [];
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  onStateChange() {
    if (this.selectedState) {
      this.getLocalGovts(this.selectedState);
    }
  }
}
