import { errorHandler } from "src/app/util/errorHandler";
import { authData } from "src/app/util/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-payment-advice",
  templateUrl: "./payment-advice.component.html",
})
export class PaymentAdviceComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}
  session = "";
  semester = "";
  sessions: any = [];
  semesters: any = [];
  notification: any = {};
  noContentFound: boolean = false;

  loading = true;
  skeletonLoader = true;
  downloader: any = null;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/Payments/PaymentAdviceSessions`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.sessions = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.loading = false;
        this.skeletonLoader = false;
        this.noContentFound = err.status === 404;
        this.notification = errorHandler(err);
      },
    });
  }

  generateAdvice() {
    if (!this.session || this.loading) return;

    this.loading = true;
    this.notification = {};

    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/payments/ViewPaymentAdvice?sessionid=${this.session}`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.downloader = {
            loading: false,
            name: res.Data.FileAttachments.Name,
            url: `data:application/octet-stream;base64,${res.Data.FileAttachments.Data}`,
          };
        } else {
          this.downloader = null;
          this.notification = errorHandler(res);
        }
        this.loading = false;
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.downloader = [];
        this.notification = errorHandler(err);
      },
    });
  }
}
