import { environment } from "src/environments/environment";
import { errorHandler } from "src/app/util/errorHandler";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-payment-index",
  templateUrl: "./payment-index.component.html",
})
export class PaymentIndexComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  downloader: any = null;

  payload: any = {};
  notification = {};

  fakeLoader = false;
  loading = false;

  links = [
    {
      title: "Generate Fees",
      slug: "/payments/generate",
      icon: "/assets/icons/papers.svg",
      text: "Add a line or two here that explains what this card does",
    },
    {
      title: "View Pay Advice",
      slug: "/payments/advice",
      icon: "/assets/icons/receipt.png",
      text: "Add a line or two here that explains what this card does",
    },
  ];

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const remita = environment.remita;
    this.links.push(
      remita
        ? {
            title: `Pay With Remita`,
            slug: `/payments/remita`,
            icon: `/assets/icons/remita.svg`,
            text: "Add a line or two here that explains what this card does",
          }
        : {
            title: `Pay Online`,
            slug: `/payments/tranzgate`,
            icon: `/assets/icons/credit-card.png`,
            text: "Add a line or two here that explains what this card does",
          }
    );

    const message: string = location.hash.slice(1);
    if (message) {
      this.notification = {
        message: message.replace(/\+/g, " "),
      };
    } else {
      const baseUrl = `${environment.BASE_URL}/payments/refereshStatus`;
      this.httpClient.get(baseUrl, this.httpOptions);
    }
  }

  printReciepts(): void {
    if (this.loading) return;

    const _checks = authData("_checks");
    const { StudentHasFilledBioData } = _checks;
    if (!StudentHasFilledBioData) {
      this.notification = {
        message: "You haven't filled your bio-data form",
        error: true,
      };
      return;
    }

    this.loading = true;
    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/payments/reciept`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        this.downloader = {
          loading: false,
          name: res.Data.Name,
          url: `data:application/octet-stream;base64,${res.Data.Data}`,
        };
      },
      error: (err) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  refreshPayment(): void {
    if (this.loading) return;

    this.notification = {};
    this.loading = true;

    const baseUrl = `${environment.BASE_URL}/payments/refereshStatus`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        this.payload = res.Data;
        this.notification = {
          error: false,
          message: "Payment refreshed successfully",
        };
      },
      error: (err) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
