import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-election-index",
  templateUrl: "./election-index.component.html",
})
export class ElectionIndexComponent implements OnInit {
  links = [
    {
      title: "Running Elections",
      slug: "/election/vote",
      icon: "/assets/icons/ballot-box.png",
      text: "Add a line or two here that explains what this card does",
    },
    {
      title: "Election Applications",
      slug: "/election/application",
      icon: "/assets/icons/document.png",
      text: "Add a line or two here that explains what this card does",
    },
  ];

  notification = {};

  ngOnInit(): void {
    const message: string = location.hash.slice(1);

    if (message)
      this.notification = {
        message: message.replace(/\+/g, " "),
      };
  }
}
