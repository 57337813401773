import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-courses-print-registration",
  templateUrl: "./courses-print-registration.component.html",
})
export class CoursesPrintRegistrationComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}
  session = "";
  semester = "";
  sessions: any = [];
  semesters: any = [];
  notification: any = {};
  noContentFound: boolean = false;

  loading = true;
  skeletonLoader = true;
  downloader: any = null;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/courses/registrationGroup`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        this.loading = false;
        this.skeletonLoader = false;

        this.sessions = [...new Set(res.Data.map((el: any) => el.Session))];
        this.semesters = [...new Set(res.Data.map((el: any) => el.Semester))];
      },
      (err) => {
        this.loading = false;
        this.skeletonLoader = false;
        this.noContentFound = err.status === 404;
        this.notification = errorHandler(err);
      }
    );
  }

  printRegistration() {
    if (!this.session || !this.semester || this.loading) return;

    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/courses/printRegistration?session=${this.session}&semester=${this.semester}`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        this.downloader = {
          loading: false,
          url: "data:application/octet-stream;base64," + res.Data.Data,
        };
      },
      (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      }
    );
  }
}
