<app-dashboard-layout>
    <div class="text-lg text-gray-600 pt-4">
        <label><b>Please see terms and conditions as well as further instructions below</b></label>
    </div>
    <!-- <div [innerHtml]="HallInstructions" class="text-sm text-gray-600 pt-4"></div> -->
    <div [innerHtml]="ProgrammeTypeAccomodationInstructions" class="text-sm text-gray-600 pt-4"></div>
    
    <div>
        <input type="checkbox" id="acknowledge" (change)="toggleAcknowledgment($event)">
        <label for="acknowledge">I have read and understood the instructions</label>
      </div>
      <button (click)="back()" class="btn btn-secondary mr-2">Back</button>
      <button *ngIf="acknowledged" (click)="acknowledgeInstructions()" class="btn btn-primary">Continue</button>            
</app-dashboard-layout>