import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { authData } from 'src/app/util/auth';
import { errorHandler } from 'src/app/util/errorHandler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-remita-sessions',
  templateUrl: './payment-remita-sessions.component.html',
  styleUrls: ['./payment-remita-sessions.component.scss']
})
export class PaymentRemitaSessionsComponent implements OnInit {

  constructor(private httpClient: HttpClient, private router: Router) { }
  session = "";
  semester = "";
  sessions: any = [];
  notification: any = {};

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/payments/tranzgateSessions`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.sessions = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
      },
      error: (err) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.skeletonLoader = false;
      },
    });
  }

  remita() {
    if (!this.session) return;
    const encodedSession = encodeURIComponent(this.session);
    this.router.navigateByUrl(`/payments/remita/${encodedSession}`);
  }

}
