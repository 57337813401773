<nav
  class="bg-white border-t rounded-t-3xl shadow-lg text-sm w-full bottom-0 z-50 overflow-hidden fixed md:border-r md:rounded-none md:min-h-screen md:border-t-0 md:order-1 md:shadow-none md:w-56 md:z-10 md:relative md:block"
>
  <ul
    class="bg-white flex justify-around md:flex-col md:justify-between md:overflow-y-auto"
  >
    <li *ngFor="let link of links">
      <a
        [routerLink]="link.slug"
        routerLinkActive="bg-primary/5 border-t-2 md:border-0 md:border-r-2 border-primary"
        [routerLinkActiveOptions]="{ exact: link.name == 'home' }"
        class="flex flex-col p-3 text-gray-600 gap-2 duration-300 items-center md:flex-row md:px-8 md:gap-4 hover:text-primary hover:pl-6"
      >
        <div class="text-lg material-icons inline-block">{{ link.icon }}</div>
        <div class="hidden whitespace-nowrap md:block">
          {{ link.title }}
        </div>
      </a>
    </li>

    <div class="border-t hidden md:block"></div>
    <li *ngFor="let link of xLinks" class="hidden md:block">
      <a
        target="_blank"
        [href]="link.href"
        class="my-2 p-3 text-gray-600 duration-300 block md:px-8 md:gap-4 hover:text-primary hover:pl-6"
      >
        <img [src]="link.icon" [alt]="link.title" class="h-auto w-20" />
      </a>
    </li>
  </ul>
</nav>
