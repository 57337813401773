import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { authData } from 'src/app/util/auth';
import { errorHandler } from 'src/app/util/errorHandler';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-application-deferment",
  templateUrl: "./application-deferment.component.html",
  styleUrls: ["./application-deferment.component.scss"],
})
export class ApplicationDefermentComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};

  session = "";
  semester = "";
  reason = "";
  response = {};

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/users/defermentapplication`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
  }
  submitForm() {
    this.loading = true;
    this.notification = {};

    this.response = {
      ResumptionSession: this.session,
      ResumptionSemester: this.semester,
      Reason: this.reason,
    };

    const baseUrl = `${environment.BASE_URL}/users/SaveDefermentApplication`;
    this.httpClient.post(baseUrl, this.response, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.router.navigateByUrl(
            "/application#Your+deferment+application+has+been+submitted+(but+not+yet+approved).+Please+ensure+it+is+approved+as+it+will+not+take+effect+until+it+is+approved+by+the+proper+authorities."
          );
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }
}
