import { Router } from "@angular/router";
import { saveAuth } from "./../../../util/auth";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
})
export class LoginPageComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}
  ngOnInit(): void {}

  payload = {
    MatricNo: "",
    Password: "",
  };
  loading = false;
  notification = {};
  errors: validations = {};

  login() {
    if (!this.payload.MatricNo || !this.payload.Password) {
      this.errors.MatricNo = !this.payload.MatricNo;
      this.errors.Password = !this.payload.Password;
      return;
    } else {
      this.errors = {};
    }

    this.notification = {};
    this.loading = true;

    const params = new HttpParams({
      fromObject: this.payload,
    });

    const baseUrl = `${environment.BASE_URL}/users/login`;
    this.httpClient
      .post(baseUrl, params)
      .subscribe({
        next: (res: any) => {
          saveAuth({ ...res?.Data, Matric: this.payload.MatricNo });
          this.router.navigateByUrl(
            res?.Data?.ResetPassword ? "/reset-password" : "/"
          );
        },
        error: (err) => {
          this.loading = false;
          this.notification = {
            error: true,
            message:
              err?.Message ||
              err?.error?.Message ||
              "An error occurred. Try again",
          };
        },
      })
      .add(() => {
        saveAuth([], "_courses");
        saveAuth({}, "_checks");
        saveAuth(null, "_news");
        saveAuth(null, "_deadlines");
      });
  }
}

interface validations {
  MatricNo?: boolean;
  Password?: boolean;
}

interface payload {
  MatricNo: string;
  Password: string;
}
