<section *ngIf="actions.length">
  <div class="pt-2" *ngIf="actions.length > 0">
    <div *ngFor="let action of actions; let i = index">
      <a
        *ngIf="action.name"
        routerLink="{{ action.slug }}"
        class="border rounded-lg mx-4 text-sm mb-2 py-3 px-4 duration-300 block hover:border-primary/50"
      >
        <div>
          {{ action.name }}
        </div>
        <div class="text-xs text-primary py-1">
          {{ action.deadline }} Days left
        </div>

        <div class="rounded-md bg-primary/20 h-1.5 mt-1.5">
          <div
            class="bg-gradient-to-r from-primary rounded-md h-full to-primary/70"
            [style]="'width: ' + action.deadline / 0.9 + '%'"
          ></div>
        </div>
      </a>
    </div>
  </div>
</section>

<section *ngIf="skeletonLoader">
  <div
    *ngFor="let action of [0, 1, 3]"
    class="border rounded-md bg-gray-300 h-12 m-4 animate-pulse"
  ></div>
</section>
