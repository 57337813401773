import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-payment-completed",
  templateUrl: "./payment-completed.component.html",
})
export class PaymentCompletedComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute) {}

  caption = "Please wait";
  icon = "";

  ngOnInit() {
    const icons: any = {
      0: "/assets/images/credit-card.png",
      1: "/assets/images/payment-check.png",
      2: "/assets/images/credit-card.png",
    };

    this.activatedRoute.queryParams.subscribe((params) => {
      const { message, success } = params;
      const msg = message.split(":");
      this.caption = msg[msg.length - 1];
      this.icon = icons[success];
    });
  }
}
