import { removeAuth } from "src/app/util/auth";

export function errorHandler(err: any) {
  if (err.status == 401) {
    removeAuth();
    location.href = "/login";
  }

  return {
    error: true,
    message:
      err?.error?.ExceptionMessage ||
      err?.error?.Message ||
      err?.Message ||
      "An error occurred.",
  };
}
