import { authData } from "src/app/util/auth";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { errorHandler } from "src/app/util/errorHandler";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-accommodation-index",
  templateUrl: "./accommodation-index.component.html",
})
export class AccommodationIndexComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  links = [
    {
      title: "accommodation Application",
      slug: "/accommodation/instructions",
      icon: "/assets/icons/home-button.png",
      text: "Add a line or two here that explains what this card does",
    },
    {
      title: "accommodation Booking",
      slug: "/accommodation/booking",
      icon: "/assets/icons/document.png",
      text: "Add a line or two here that explains what this card does",
    },
    {
      title: "ID Replacement Applications",
      slug: "/accommodation/id-replacement",
      icon: "/assets/icons/id-card.png",
      text: "Add a line or two here that explains what this card does",
    },
  ];

  notification = {};

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const message: string = location.hash.slice(1);

    if (message)
      this.notification = {
        message: message.replace(/\+/g, " "),
      };

    // Accommodation checks
    const baseUrl = `${environment.BASE_URL}/accomodation/accommodationChecks`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          // this.sessions = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
      },
      error: (err: any) => {
        this.notification = errorHandler(err);
      },
    });
  }
}
