import { errorHandler } from "src/app/util/errorHandler";
import { authData, saveAuth } from "src/app/util/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  loading = false;
  notification = {};
  payload = {
    OldPassword: "",
    NewPassword: "",
    NewPasswordConfirmation: "",
  };

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {}

  resetPassword() {
    this.loading = true;
    const baseUrl = `${environment.BASE_URL}/users/saveChangePassword`;
    this.httpClient
      .post(baseUrl, this.payload, this.httpOptions)
      .subscribe({
        next: (res: any) => {
          if (res.Success) {
            this.router.navigateByUrl("/");
          } else {
            this.notification = {
              error: true,
              message: res.Message || "An error occurred.",
            };
          }
        },
        error: (err: any) => {
          this.notification = errorHandler(err);
        },
      })
      .add(() => {
        this.loading = false;
      });
  }
}
