import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-notification-alert",
  templateUrl: "./notification-alert.component.html",
})
export class NotificationAlertComponent implements OnInit {
  constructor() {}

  @Input() data: Notification | null = null;
  ngOnInit(): void {}
}

interface Notification {
  error?: boolean;
  message?: string;
}
