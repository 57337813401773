export function authData(key?: string) {
  const _auth: string | null = sessionStorage.getItem(key || "_auth");

  if (!_auth) location.href = "/login";
  return _auth ? JSON.parse(_auth) : null;
}

export function saveAuth(data: any, key?: string) {
  sessionStorage.setItem(key || "_auth", JSON.stringify(data));
}

export function removeAuth() {
  sessionStorage.clear();
}
