<app-dashboard-layout [notDashboard]="true" [loading]="loading">
  <div class="flex text-sm pb-4 text-gray-500 gap-2 items-center">
    <a routerLink="/news" class="text-primary">News</a> &raquo;
    {{ news.Subject || "Please wait" }}
  </div>

  <section *ngIf="refreshing">
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div
      class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"
    ></div>
  </section>
  <section *ngIf="!refreshing" class="bg-white border rounded-md p-4 md:p-8">
    <header class="border-b pb-4">
      <h1 class="font-bold text-lg pb-2 uppercase">
        {{ news.ShortDescription }}
      </h1>
      <div class="flex text-sm text-gray-400 gap-2 items-center">
        <div class="text-lg material-icons inline-block">history</div>
        <div>Last Modified: {{ formatDate(news.ModifiedDateTime) }}</div>
      </div>
    </header>

    <div class="max-w-screen-md py-6" [innerHTML]="news.Body"></div>
  </section>
</app-dashboard-layout>
