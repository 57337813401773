import { errorHandler } from "src/app/util/errorHandler";
import { authData } from "src/app/util/auth";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "./../../../../environments/environment";

@Component({
  selector: "app-bio-data-education",
  templateUrl: "./bio-data-education.component.html",
})
export class BioDataEducationComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  school = {
    SchoolName: "",
    SchoolAddress: "",
    SchoolType: "",
    QualificationObtained: "",
    Grade: "",
    CourseOfStudy: "",
    PreviousSchoolMatricNo: "",
    FromDate: "",
    ToDate: "",
  };
  payload: any = {
    StudentEducationalHistoryDTO: [
      {
        ...this.school,
      },
    ],
    EverAppearedbeforepanel: false,
    Outcome: "",
  };

  grades = [
    "Not Set",
    "Pass",
    "Distinction",
    "Merit",
    "Unclassified",
    "Credit",
    "1st Class",
    "2nd Class Upper",
    "2nd Class Lower",
    "3rd Class",
    "Upper Credit",
    "Lower Credit",
  ];

  institutions = [
    "Not Set",
    "Primary School",
    "Secondary School",
    "Polytechnic",
    "University",
    "Monotechnic",
    "Other",
  ];

  qualification = [
    "Not Set",
    "Primary School Leaving Certificate",
    "WASSCE",
    "HND",
    "OND",
    "NECO",
    "Foundation",
    "Cambridge O Levels",
    "Bachelors Degree",
    "Masters Degree",
    "Doctorate Degree",
    "Mphil",
    "PGD",
    "Phd",
    "Diploma",
    "NCE",
    "Others",
  ];

  loading = false;
  skeletonLoader = true;
  notification = {};

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/biodata/EducationalHistory`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        this.skeletonLoader = false;
        this.payload.StudentEducationalHistoryDTO = [...res.Data];

        this.payload.StudentEducationalHistoryDTO =
          this.payload.StudentEducationalHistoryDTO.map((el: any) => {
            return {
              ...el,
              ToDate: el.ToDate.split("T")[0],
              FromDate: el.FromDate.split("T")[0],
            };
          });
      },
      (err) => {
        this.skeletonLoader = false;
      }
    );
  }

  updateEducationHistory() {
    this.loading = true;
    this.notification = {};

    const baseUrl = `${environment.BASE_URL}/biodata/SaveEducationalHistory`;
    this.httpClient.put(baseUrl, this.payload, this.httpOptions).subscribe(
      (res) => {
        this.router.navigateByUrl("/bio-data#Education+history+updated");
      },
      (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      }
    );
  }

  totalSchools(): any[] {
    return new Array(this.payload.StudentEducationalHistoryDTO.length);
  }

  addSchool() {
    this.payload.StudentEducationalHistoryDTO.push({ ...this.school });
  }

  removeSchool(index: number) {
    const StudentEducationalHistoryDTO = [
      ...this.payload.StudentEducationalHistoryDTO,
    ];

    this.payload.StudentEducationalHistoryDTO = [
      ...StudentEducationalHistoryDTO.slice(0, index),
      ...StudentEducationalHistoryDTO.slice(index + 1),
    ];
  }
}
