<app-dashboard-layout>
  <section
    class="flex flex-col mx-auto max-w-screen-md p-4 z-20 justify-center"
  >
    <div class="">
      <app-no-content [caption]="caption" [icon]="icon"></app-no-content>
    </div>

    <div class="flex w-full justify-center">
      <app-button-primary routerLink="/payments" class="max-w-full w-full">
        Continue
      </app-button-primary>
    </div>
  </section>
</app-dashboard-layout>
