import { errorHandler } from "src/app/util/errorHandler";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { authData } from "src/app/util/auth";
import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-catchup",
  templateUrl: "./application-catchup.component.html",
})
export class ApplicationCatchupComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  unit = {
    max: 0,
    show: false,
  };
  course = {
    max: 0,
    show: false,
  };

  payload: any = {};

  notification = {};
  downloader: any = null;

  loading = false;
  refreshing = false;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const { CatchUp, CatchUpApplicationType } = authData("_checks");
    if (!CatchUp || CatchUpApplicationType < 1) {
      this.router.navigateByUrl("/application");
    }

    this.unit.show = CatchUpApplicationType == 1;
    this.course.show = CatchUpApplicationType == 2;

    const baseUrl = `${environment.BASE_URL}/payments/catchUpApplication`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload.max = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
        this.refreshing = false;
      },
      error: (err) => {
        this.refreshing = false;
        this.notification = errorHandler(err);
      },
    });
  }

  submitForm() {
    this.loading = true;
    this.notification = {};

    const baseUrl = `${environment.BASE_URL}/payments/saveCatchUpApplication`;
    this.httpClient.post(baseUrl, this.payload, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.router.navigateByUrl(
            "/payments#Supplementary+Application+Submitted.+Please+proceed+with+the+payment"
          );
        } else {
          this.notification = errorHandler(res);
        }

        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }
}
