<app-dashboard-layout [loading]="loading" [downloader]="downloader">
    <app-notification-alert [data]="notification"></app-notification-alert>
    <section class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
        <app-dashboard-card *ngFor="let link of links" [routerLink]="link.slug" [title]="link.title" [icon]="link.icon"
            [caption]="link.text">
        </app-dashboard-card>
        <app-dashboard-card *ngIf="StudentProfileView" (click)="printProfile()" title="Print Academic Profile"
            icon="/assets/icons/printer.png" caption="Print Academic Profile">
        </app-dashboard-card>
    </section>
</app-dashboard-layout>