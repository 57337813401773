<app-dashboard-layout [loading]="loading">
  <app-notification-alert [data]="notification"></app-notification-alert>

  <form class="bg-white border rounded-md p-4">
    <header class="border-b flex mb-6 w-full pb-1 gap-4 justify-between">
      <h2 class="font-medium whitespace-nowrap uppercase">My Courses</h2>

      <app-course-registration-form>
        <button
          class="rounded font-medium bg-primary/90 text-white py-2 px-4 whitespace-nowrap md:px-8 hover:bg-primary"
        >
          Register Course
        </button>
      </app-course-registration-form>
    </header>

    <app-no-content
      *ngIf="!courses.length && !skeletonLoader"
      caption="No courses to show."
    >
    </app-no-content>

    <section class="grid gap-4 :gap-6 grid-cols-1 md:grid-cols-2">
      <ng-template [ngIf]="skeletonLoader && !courses.length">
        <div
          *ngFor="let i of [1, 2, 3, 4, 5, 6]"
          class="border rounded-md bg-gray-300 h-20 animate-pulse"
        ></div>
      </ng-template>

      <ng-template [ngIf]="courses.length > 0">
        <div
          *ngFor="let course of courses"
          class="rounded-md border-2 p-4 transform duration-200 group hover:border-primary hover:bg-primary/5 hover:scale-105"
        >
          <div
            class="font-medium text-base pb-4 capitalize group-hover:text-primary"
          >
            {{ course?.CourseTitle }}
          </div>
          <section class="grid grid-cols-1 md:grid-cols-2">
            <div
              class="flex text-xs text-gray-500 gap-4 uppercase hover:border-primary"
            >
              <div class="whitespace-nowrap">
                {{ course?.CourseCode }}
              </div>
              <div class="whitespace-nowrap">
                {{ course?.CourseUnits }} UNITS
              </div>
              <div class="whitespace-nowrap">
                {{ course?.Compulsory ? "Compulsory" : "Elective" }}
              </div>
            </div>
          </section>
        </div>
      </ng-template>
    </section>
  </form>
</app-dashboard-layout>
