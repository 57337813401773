import { errorHandler } from "src/app/util/errorHandler";
import { HttpHeaders } from "@angular/common/http";
import { authData } from "src/app/util/auth";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-election-running",
  templateUrl: "./election-running.component.html",
})
export class ElectionRunningComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  refreshing = true;
  elections: any = [];
  notification = {};

  ngOnInit(): void {
    this.refreshing = true;
    const baseUrl = `${environment.BASE_URL}/elections/runningElections`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.elections = res.Data;
        } else {
          this.notification = errorHandler({
            error: true,
            message: res.Message,
          });
        }
      },
      error: (err) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.refreshing = false;
      },
    });
  }
}
