import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { authData } from "src/app/util/auth";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-election-candidate-application",
  templateUrl: "./election-candidate-application.component.html",
})
export class ElectionCandidateApplicationComponent implements OnInit {
  constructor(private route: ActivatedRoute, private httpClient: HttpClient) {}

  loading = false;
  refreshing = true;
  _auth = authData();
  notification = {};

  questions: any = [];
  positions: any = [];
  payload: any = { position: "", questions: [] };

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._auth.Token,
    }),
  };

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.refreshing = true;
      const baseUrl = `${environment.BASE_URL}/elections/electionApplications?electionId=${params.id}`;

      this.httpClient.get(baseUrl, this.httpOptions).subscribe({
        next: (res: any) => {
          this.positions = res.Data.ElectionPositionDTO;
          this.payload.questions = res.Data.ElectionApplicationFormQuestionsDTO;
        },
        complete: () => {
          this.refreshing = false;
        },
      });
    });
  }

  toggleCheckBox(index: number) {
    this.payload.questions[index].Answer = !this.payload.questions[index].Answer;
  }

  submitApplication() {
    const payload = {
      ElectionApplicationFormQuestionsDTO: [...this.payload.questions],
      ElectionPositionDTO: {
        ...this.positions.find((el: any) => el.PositionID == this.payload.payload.PositionID),
      },
    };

    console.log(payload);
  }
}
