import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";

@Component({
  selector: "app-bio-data-index",
  templateUrl: "./bio-data-index.component.html",
})
export class BioDataIndexComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}
  links = [
    {
      title: "Update Passport",
      slug: "/bio-data/passport",
      icon: "/assets/icons/photo.png",
      text: "Add a line or two here that explains what this card does",
    },
    {
      title: "Basic Data",
      slug: "/bio-data/basic",
      icon: "/assets/icons/visa.png",
      text: "Add a line or two here that explains what this card does",
    },
    {
      title: "Medical History",
      slug: "/bio-data/medical",
      icon: "/assets/icons/doctor.png",
      hide: !authData("_checks")?.RequireStudentToFillMedicalForm,
      text: "Add a line or two here that explains what this card does",
    },
    {
      title: "Education History",
      slug: "/bio-data/education",
      icon: "/assets/icons/scholarship.png",
      hide: !authData("_checks")?.BiodataHasSecondStage,
      text: "Add a line or two here that explains what this card does",
    },
    {
      title: "Document Upload",
      slug: "/bio-data/documents",
      icon: "/assets/icons/document.png",
      text: "Add a line or two here that explains what this card does",
    },
  ];

  notification = {};
  loading = false;
  downloader: any = null;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    this.links = this.links.filter((el: any) => !el?.hide);
    const message: string = location.hash.slice(1);

    if (message)
      this.notification = {
        message: message.replace(/\+/g, " "),
      };
  }

  printBiodata() {
    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/biodata/PrintBioData`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        this.downloader = {
          loading: false,
          name: res.Data?.Name || "bioData.pdf",
          url: `data:application/octet-stream;base64,${res.Data.Data}`,
        };
      },
      (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      }
    );
  }

  printAdmissionLetter() {
    if (this.loading) return;

    const _checks = authData("_checks");
    const { PassportUploaded } = _checks;
    if (!PassportUploaded) {
      this.notification = {
        message: "Please upload your passport photograph",
        error: true,
      };
      return;
    }

    this.loading = false;
    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/bioData/PrintAdmissionLetter`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        this.downloader = {
          loading: false,
          name: res.Data?.Name || "admission-letter.pdf",
          url: `data:application/octet-stream;base64,${res.Data.Data}`,
        };
      },
      error: (err) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
