<app-dashboard-layout>
    <app-notification-alert [data]="notification"></app-notification-alert>

    <form class="flex flex-col mx-auto max-w-screen-md p-4 z-20 justify-center">
        <h1 class="font-medium text-center text-lg">
            Select a session to make payment
        </h1>

        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <section *ngIf="!skeletonLoader">
            <div class="py-8">
                <div class="bg-white border border-primary rounded-lg w-full p-2">
                    <select name="session" [(ngModel)]="session" class="w-full p-2 focus:outline-none">
                        <option disabled value="">Select a session</option>
                        <option *ngFor="let session of sessions">{{ session }}</option>
                    </select>
                </div>
            </div>

            <app-button-primary class="w-full" (click)="remita()" [disabled]="skeletonLoader || loading">
                Next
            </app-button-primary>
        </section>
    </form>
</app-dashboard-layout>