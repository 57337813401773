import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { saveAuth } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-email-verify",
  templateUrl: "./email-verify.component.html",
  styleUrls: ["./email-verify.component.scss"],
})
export class EmailVerifyComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}
  loading = true;
  errorMessage = "";
  payload = "";
  successMessage = "";
  verified = false;

  ngOnInit(): void {
    const params = new URLSearchParams(location.search);
    const payload = {
      Token: params.get("token") || "",
    };

    const httpParams = new HttpParams({
      fromObject: payload,
    });

    const baseUrl = `${environment.BASE_URL}/biodata/confirmverificationmail`;
    this.httpClient.post(baseUrl, httpParams).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.verified = true;
          this.successMessage = "Email Verified";
        } else {
          this.verified = false;
          this.errorMessage = res.Message;
        }
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this.errorMessage =
          err?.Message || err?.error?.Message || "An error occurred. Try again";
      },
    });
  }
}
