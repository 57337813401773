<header
  class="bg-white border-b flex py-2 px-4 items-center justify-between md:px-8"
>
  <h1 class="flex-shrink-0 leading-none">
    <a routerLink="/" class="flex items-center">
      <img [src]="logo" width="37" height="37" alt="logo" />
      <div class="font-semibold text-primary ml-2 uppercase">
        <div class="hidden md:block">{{ long_name }}</div>
        <div class="md:hidden">{{ short_name }}</div>
      </div>
    </a>
  </h1>

  <div class="flex flex-shrink-0 gap-4 items-center">
    <app-account-dropdown></app-account-dropdown>
  </div>
</header>
