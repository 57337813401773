import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent implements OnInit {
  constructor(private httpClient: HttpClient) {}

  loading = false;
  notification = {};
  MatricNo = "";

  ngOnInit(): void {}

  resetPassword() {
    this.loading = true;
    const payload = { MatricNo: this.MatricNo };

    const baseUrl = `${environment.BASE_URL}/users/forgotPassword`;
    this.httpClient
      .post(baseUrl, payload)
      .subscribe({
        next: (res: any) => {
          if (res.Success) {
            this.notification = {
              message:
                "A password reset link was sent. Click the link in the email to create a new password.",
            };
          } else {
            this.notification = {
              error: true,
              message: res.Message || "An error occurred.",
            };
          }
        },
        error: (err: any) => {
          this.notification = {
            error: true,
            message:
              err?.Message ||
              err?.error?.Message ||
              "An error occurred. Try again",
          };
        },
      })
      .add(() => {
        this.loading = false;
      });
  }
}
