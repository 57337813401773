import { errorHandler } from "src/app/util/errorHandler";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { authData, saveAuth } from "src/app/util/auth";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-pending-actions",
  templateUrl: "./pending-actions.component.html",
})
export class PendingActionsComponent implements OnInit {
  _deadlines = authData("_deadlines");
  actions: any = this._deadlines || [];
  skeletonLoader = false;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${authData().Token}`,
    }),
  };

  constructor(private httpClient: HttpClient, private router: Router) {}

  ngOnInit(): void {
    if (this._deadlines) return;
    this.skeletonLoader = true;

    const baseUrl = `${environment.BASE_URL}/courses/deadlines`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        const init: any = {
          CreatedDateTime: {
            title: "",
            slug: "",
          },
          PaymentDeadline: {
            title: "Complete your payment",
            slug: "/payments",
          },
          RegistrationDeadline: {
            title: "Complete your course registration",
            slug: "/courses",
          },
          RegistrationModificationsDeadline: {
            title: "Edit your course registration",
            slug: "/courses",
          },
        };

        this.skeletonLoader = false;
        this.actions = Object.keys(res.Data).map((el: any) => {
          return {
            name: init[el].title,
            slug: init[el].slug,
            deadline: this.calculateDeadline(
              +new Date(res.Data[el].replace("/", "-"))
            ),
          };
        });
        saveAuth([...this.actions], "_deadlines");
      },
      error: (err) => {
        this.skeletonLoader = false;
        const e = errorHandler(err);
      },
    });
  }

  calculateDeadline(val: number) {
    const days = Math.round((val - +new Date()) / 8.64e7);
    if (days < 0) {
      return 0;
    }
    return days > 90 ? 90 : days;
  }
}
