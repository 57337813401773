<app-dashboard-layout [notDashboard]="true">
  <section class="bg-white border rounded-md p-4">
    <header class="border-b flex text-lg mb-6 w-full pb-4 gap-8 md:text-xl">
      Education History
    </header>

    <div
      *ngIf="skeletonLoader"
      class="max-w-screen-md grid pb-4 gap-4 gap-8 grid-cols-1 md:grid-cols-2"
    >
      <div
        *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]"
        class="border rounded-md bg-gray-300 h-20 animate-pulse"
      ></div>
    </div>

    <app-notification-alert [data]="notification"></app-notification-alert>
    <form
      ngNativeValidate
      *ngIf="!skeletonLoader"
      id="biodata-education"
      (ngSubmit)="updateEducationHistory()"
      class="max-w-screen-md grid gap-8 grid-cols-1"
    >
      <div
        *ngFor="let x of totalSchools(); let i = index"
        class="border-primary-lighter border-b-2 grid pb-8 gap-8 grid-cols-1"
      >
        <section>
          <div class="font-medium text-grey-darker text-sm pb-2 block">
            Name of School
          </div>
          <input
            required
            type="text"
            [name]="'SchoolName' + i"
            placeholder="Name of School"
            [(ngModel)]="payload.StudentEducationalHistoryDTO[i].SchoolName"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </section>

        <section>
          <div class="font-medium text-grey-darker text-sm pb-2 block">
            Type of Institution
          </div>

          <div
            class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <select
              required
              [name]="'SchoolType' + i"
              [(ngModel)]="payload.StudentEducationalHistoryDTO[i].SchoolType"
              class="w-full p-1 focus:outline-none"
            >
              <option disabled value="">Select option</option>
              <option
                *ngFor="let option of institutions"
                [value]="option.replace(' ', '')"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </section>

        <section>
          <div class="font-medium text-grey-darker text-sm pb-2 block">
            Course of study
          </div>
          <input
            required
            type="text"
            [name]="'CourseOfStudy' + i"
            placeholder="Course of study"
            [(ngModel)]="payload.StudentEducationalHistoryDTO[i].CourseOfStudy"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </section>

        <section>
          <div class="font-medium text-grey-darker text-sm pb-2 block">
            Previous school matric no. (Optional)
          </div>
          <input
            required
            type="text"
            [name]="'PreviousSchoolMatricNo' + i"
            placeholder="Previous school matric no."
            [(ngModel)]="
              payload.StudentEducationalHistoryDTO[i].PreviousSchoolMatricNo
            "
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </section>

        <section>
          <div class="font-medium text-grey-darker text-sm pb-2 block">
            School Address
          </div>
          <input
            required
            type="text"
            [name]="'SchoolAddress' + i"
            placeholder="Name of School"
            [(ngModel)]="payload.StudentEducationalHistoryDTO[i].SchoolAddress"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </section>

        <section>
          <div class="font-medium text-grey-darker text-sm pb-2 block">
            Qualification Obtained
          </div>

          <div
            class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <select
              required
              [name]="'QualificationObtained' + i"
              [(ngModel)]="
                payload.StudentEducationalHistoryDTO[i].QualificationObtained
              "
              class="w-full p-1 focus:outline-none"
            >
              <option disabled value="">Select option</option>
              <option
                *ngFor="let option of qualification"
                [value]="option.replace(' ', '')"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </section>

        <section>
          <div class="font-medium text-grey-darker text-sm pb-2 block">
            Grade
          </div>

          <div
            class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <select
              [name]="'Grade' + i"
              [(ngModel)]="payload.StudentEducationalHistoryDTO[i].Grade"
              class="w-full p-1 focus:outline-none"
            >
              <option disabled value="">Select option</option>
              <option
                *ngFor="let option of grades"
                [value]="option.replace(' ', '')"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </section>

        <section class="grid gap-8 grid-cols-1 md:gap-8 md:grid-cols-2">
          <label class="block">
            <div class="font-medium text-grey-darker text-sm pb-2">
              From Date*
            </div>
            <input
              required
              type="date"
              [name]="'FromDate' + i"
              placeholder="From Date"
              [(ngModel)]="payload.StudentEducationalHistoryDTO[i].FromDate"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm pb-2">
              To Date*
            </div>
            <input
              required
              type="date"
              [name]="'ToDate' + i"
              placeholder="To Date"
              [(ngModel)]="payload.StudentEducationalHistoryDTO[i].ToDate"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>
        </section>
        <div>
          <app-button-primary
            [outline]="true"
            class="flex gap-4"
            [disabled]="loading"
            (click)="removeSchool(i)"
          >
            <div>REMOVE SCHOOl</div>
            <div class="material-icons">delete</div>
          </app-button-primary>
        </div>
      </div>

      <label class="block">
        <div
          class="border border-primary-lighter rounded flex text-sm w-full py-2 px-3 text-gray-700 justify-between items-center appearance-none focus:outline-none focus-within:border-primary-light"
        >
          <div>Have you ever appeared before a disciplinary panel?</div>
          <input
            type="checkbox"
            name="EverAppearedbeforepanel"
            [(ngModel)]="payload.EverAppearedbeforepanel"
          />
        </div>
      </label>

      <section *ngIf="payload.EverAppearedbeforepanel">
        <div class="font-medium text-grey-darker text-sm pb-2 block">
          What was the outcome?
        </div>
        <input
          required
          type="text"
          name="Outcome"
          placeholder="Outcome?"
          [(ngModel)]="payload.Outcome"
          class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
        />
      </section>

      <nav class="flex flex-col py-6 gap-4">
        <div class="grid py-6 gap-8 grid-cols-1 md:grid-cols-2">
          <app-button-primary
            type="submit"
            class="w-full"
            [outline]="true"
            [disabled]="loading"
            (click)="addSchool()"
          >
            <div>ADD SCHOOl</div>
            <div class="material-icons">add</div>
          </app-button-primary>

          <app-button-primary type="submit" [disabled]="loading" class="w-full">
            UPDATE EDUCATION HISTORY
          </app-button-primary>
        </div>

        <app-notification-alert [data]="notification"></app-notification-alert>
      </nav>
    </form>
  </section>
</app-dashboard-layout>
