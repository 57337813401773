import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dashboard-index",
  templateUrl: "./dashboard-index.component.html",
})
export class DashboardIndexComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
