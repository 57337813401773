<app-dashboard-layout [notDashboard]="true" [loading]="loading">
  <header class="text-lg w-full pb-6 gap-8 md:text-xl">
    News and Updates
    <div class="text-sm text-gray-600">
      Check out the latest news and stay informed
    </div>
  </header>

  <section *ngIf="refreshing">
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div
      class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"
    ></div>
  </section>

  <div class="flex flex-col gap-4">
    <a
      *ngFor="let item of news"
      [href]="'/news/' + item.Id"
      class="bg-white rounded-md cursor-pointer border-2 p-4 transform duration-200 block group md:p-6 hover:border-primary hover:bg-primary/5 hover:scale-95"
    >
      <div
        class="font-medium text-base pb-1 capitalize group-hover:text-primary"
      >
        {{ item.ShortDescription }}
      </div>
      <div class="text-xs pb-6 text-gray-500">
        {{ formatDate(item.ModifiedDateTime) }}
      </div>

      <section class="flex gap-8 justify-between items-center">
        <div class="text-sm text-gray-500 uppercase">
          {{ compareDate(item.EndDateTime) }}
        </div>
        <a class="font-medium text-primary text-sm">Read More</a>
      </section>
    </a>
  </div>
</app-dashboard-layout>
