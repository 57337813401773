import { errorHandler } from "src/app/util/errorHandler";
import { authData, saveAuth } from "src/app/util/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "./../../../../environments/environment";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-courses-current",
  templateUrl: "./courses-current.component.html",
})
export class CoursesCurrentComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  courses: any = [];
  notification: any = {};

  loading = true;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/courses/currentCourses`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        this.loading = false;
        this.skeletonLoader = false;
        this.courses = res.Data?.Courses || [];
      },
      (err) => {
        this.loading = false;
        this.skeletonLoader = false;

        this.notification = errorHandler(err);
        if (this.notification.message == "No course was found") {
          this.notification = {};
        }
      }
    );
  }

  registrationFormStatus: boolean = false;
  showRegistrationForm() {
    this.registrationFormStatus = !this.registrationFormStatus;
  }
}
