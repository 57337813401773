import { environment } from "src/environments/environment";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-navigation-drawer",
  templateUrl: "./navigation-drawer.component.html",
})
export class NavigationDrawerComponent implements OnInit {
  constructor() {}

  links = [
    {
      name: "home",
      title: "Dashboard",
      slug: "/",
      icon: "grid_view",
    },
    {
      name: "biodata",
      title: "Student Data",
      slug: "/bio-data",
      icon: "contact_page",
    },
    {
      name: "payment",
      title: "Payments",
      slug: "/payments",
      icon: "credit_card",
    },
    {
      name: "course",
      title: "Course Registration",
      slug: "/courses",
      icon: "menu_book",
    },
    {
      name: "result",
      title: "Results",
      slug: "/results",
      icon: "fact_check",
    },
    {
      name: "appointment",
      title: "Appointment",
      slug: "/appointment",
      icon: "book_online",
    },
    {
      name: "election",
      title: "Election",
      slug: "/election",
      icon: "how_to_vote",
    },
    {
      name: "accommodation",
      title: "Accommodation",
      slug: "/accommodation",
      icon: "house",
    },
    {
      name: "application",
      title: "Student Applications",
      slug: "/application",
      icon: "note_alt",
    },
  ];

  xLinks = [
    {
      title: "Webtest",
      href: "https://webtest.ng/",
      icon: "/assets/logos/webtest.png",
    },
    {
      title: "Impata",
      href: "https://www.impata.com/",
      icon: "/assets/logos/impata.png",
    },
  ];

  ngOnInit(): void {
    const navItems: any = [];
    const navLinks: any = environment.nav_links;
    Object.keys(navLinks).forEach((x: any) => {
      if (navLinks[x]) navItems.push(x);
    });

    this.links = this.links.filter((el) => navItems.includes(el.name));
  }
}
