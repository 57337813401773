<section *ngIf="skeletonLoader">
  <div
    *ngFor="let action of [0, 1, 3]"
    class="border rounded-md bg-gray-300 h-12 m-4 animate-pulse"
  ></div>
</section>

<div class="h-full py-8">
  <ng-template [ngIf]="news.length" [ngIfElse]="noNews">
    <h2 class="font-medium text-center text-xl">News and Updates</h2>
    <div class="border-t mt-2">
      <a
        *ngFor="let newInfo of news; let i = index"
        routerLink="/news/{{ newInfo.Id }}"
        class="border-b p-4 duration-300 block hover:bg-blue-50"
      >
        <p class="text-sm">
          {{ newInfo.ShortDescription }} <br /><time
            dateTime="{{ newInfo.ModifiedDateTime }}"
            class="text-xs text-primary"
          >
            {{ newInfo.ModifiedDateTime | date: "EEE, MMM d, y, h:mm a" }}
          </time>
        </p>
      </a>
    </div>
  </ng-template>

  <ng-template #noNews>
    <div *ngIf="!skeletonLoader" class="placeholder-text">
      There is no new info.
    </div>
  </ng-template>
</div>
