import { errorHandler } from "src/app/util/errorHandler";
import { authData } from "src/app/util/auth";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "./../../../../environments/environment";

@Component({
  selector: "app-bio-data-medical",
  templateUrl: "./bio-data-medical.component.html",
})
export class BioDataMedicalComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {
    Sport: "",
    DoYouSmoke: "",
    Hospitalized: "No",
    HealthStatus: "",
    DoYouTakeAlcohol: "NO",
    OtherillnessHistory: "",
    MedicationRequired: "No",
    SpecificDisability: "No",
    MedicalList: ["", "", ""],
    OtherMedicalConditions: "",
    IllnessList: ["", "", ""],
    FamilyHistoryDTO: {
      FamilyHistoryofHypertension: false,
      FamilyHistoryofDiabetesMellitus: false,
      FamilyHistoryofEpilepsy: false,
      FamilyHistoryofMentalIllness: false,
      FamilyHistoryofTuberculosis: false,
      FamilyHistoryofBreastCancer: false,
    },
    VaccineDTO: {
      BCGVaccine: false,
      TetanusVaccine: false,
      HepatitisBVaccine: false,
      YellowFeverVaccine: false,
      DPTVaccine: false,
      CSMVaccine: false,
      MMRVaccine: false,
      OralPolioVaccine: false,
    },
  };

  illnesses = [
    "None",
    "Abnormal Heart Beat",
    "Abnormal Nipple Discharge",
    "Abnormal Vaginal Bleeding",
    "Allergy",
    "Anxiety Disorder",
    "Arthritis",
    "Asthma",
    "Drug Addiction",
    "Epilepsy",
    "Fainting Attacks",
    "FRACTURE",
    "Hepatitis",
    "High Blood Cholesterol",
    "Hypertension",
    "Malaria",
    "Meningitis",
    "Menstral Disorder",
    "Mental Illness/Depression",
    "Pneumonia",
    "Rape Victim",
    "Recurrent Migraine",
    "Sickle Cell Disease",
    "STD",
    "Surgery",
    "Tuberculosis",
    "Typhoid",
    "Peptic Ulcer Disease",
    "Uterine Fibroids",
    "UTI",
    "Others",
  ];

  medicalConditions = [
    "NONE",
    "Asthma",
    "Blindness",
    "Congenital Heart Disease",
    "Deafness",
    "Diabetes Mellitus",
    "Epilesy",
    "Hypertension",
    "Physical Deformity",
    "Psychiatric illness",
    "Peptic Ulcer",
    "Sickle Cell Anaemia",
    "Others",
  ];

  sports = ["No", "Rarely", "Regularly", "Fairly Regularly"];

  loading = false;
  skeletonLoader = true;
  notification = {};

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/bioData/medicalHistory`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        this.skeletonLoader = false;
        this.payload = res.Data || this.payload;
      },
      (err) => {
        this.skeletonLoader = false;
      }
    );
  }

  updateMedicalHistory() {
    this.loading = true;
    this.notification = {};

    const payload = {
      ...this.payload,
      OtherillnessHistory: "None",
      OtherMedicalConditions: "None",
    };

    const baseUrl = `${environment.BASE_URL}/bioData/SaveMedicalHistory`;
    this.httpClient.put(baseUrl, payload, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload = res.Data;
          this.notification = {
            error: false,
            message: "Operation Successful",
          };
          this.router.navigateByUrl("/bio-data#Medical+history+updated");
        } else {
          this.notification = {
            error: true,
            message: res.Message || "An error occurred.",
          };
        }
      },
      error: (err) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  getArray(obj: any): string[] {
    return Object.keys(obj);
  }

  toggleCheckBox(variable: string) {
    this.payload[variable] = this.payload[variable] == "Yes" ? "No" : "Yes";
  }
}
