import { environment } from "src/environments/environment";
import { authData } from "src/app/util/auth";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-top-nav-bar",
  templateUrl: "./top-nav-bar.component.html",
})
export class TopNavBarComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}

  _auth = authData();
  logo = environment.logo;
  long_name = environment.long_name;
  short_name = environment.short_name;
}
