import { environment } from "src/environments/environment";
import { Component } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { filter, map, switchMap } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private title: Title
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activeRoute),
        map((route) => route.firstChild),
        switchMap((route) => route!.data),
        map(
          (data) =>
            `${data && data.title ? `${data.title} | ` : ""} ${
              environment.long_name
            } | Student Portal`
        )
      )
      .subscribe((currentTitle) => this.title.setTitle(currentTitle));
  }
}
