import { errorHandler } from "src/app/util/errorHandler";
import { ActivatedRoute, Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "./../../../../environments/environment";

@Component({
  selector: "app-payment-remita",
  templateUrl: "./payment-remita.component.html",
})
export class PaymentRemitaComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router, private activatedRoute: ActivatedRoute,) { }

  total = 0;
  payments: any = null;
  paymentType = "General";
  session = "";

  notification = {};
  downloader: any = null;

  loading = false;
  refreshing = true;
  rrr = "";

  show = false;
  selectedPayment: any = null;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };
  baseUrl = `${environment.BASE_URL}/payments/payGeneralFeesToRemita`;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.session = params.id;
      const baseUrl = `${environment.BASE_URL}/payments/RemitaOnlinePayment?sessionId=${this.session}`;
      this.httpClient.get(baseUrl, this.httpOptions).subscribe(
        (res: any) => {
          if (res.Success) {
            this.refreshing = false;
            this.payments = res.Data;
            this.total = res.Data.reduce(
              (previousValue: any, currentValue: any) =>
                previousValue + +currentValue.Amount,
              0
            );
          } else {
            this.notification = errorHandler(res);
          }
        },
        (err) => {
          this.refreshing = false;
          this.notification = errorHandler(err);
        }
      );
    });
  }

  onClickPay(payload: any) {
    this.toggleDialog();
    this.selectedPayment = payload;
    this.refreshRRR();
  }

  refreshRRR() {
    switch (this.paymentType) {
      case "Minimum Fee For Registration":
        this.baseUrl = `${environment.BASE_URL}/payments/payRegistrationMinimumFeesToRemita`;
        break;

      case "Minimum Fee For Docket":
        this.baseUrl = `${environment.BASE_URL}/payments/payDocketMinimumFeesToRemita`;
        break;

      default:
        this.baseUrl = `${environment.BASE_URL}/payments/payGeneralFeesToRemita`;
        break;
    }

    this.loading = true;
    this.httpClient
      .post(
        this.baseUrl + `?isAdvice=false`,
        [this.selectedPayment],
        this.httpOptions
      )
      .subscribe({
        next: (res: any) => {
          if (res.Success) {
            this.rrr = res.Data.RRR;
          } else {
            this.toggleDialog();
            this.notification = errorHandler(res);
          }
        },
        error: (err) => {
          this.toggleDialog();
          this.notification = errorHandler(err);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  toggleDialog() {
    this.show = !this.show;
  }

  generateAdvice() {
    if (this.loading) return;

    if (this.show) this.show = false;

    this.loading = true;
    this.notification = {};
    this.downloader = {
      loading: true,
    };

    this.httpClient
      .post(
        this.baseUrl + `?isAdvice=true`,
        [this.selectedPayment],
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.loading = false;

          this.downloader = {
            loading: false,
            name: res.Data.PaymentAdvicePDF.Name,
            url: `data:application/octet-stream;base64,${res.Data.PaymentAdvicePDF.Data}`,
          };
        },
        (err) => {
          this.downloader = null;
          this.notification = errorHandler(err);
        }
      );
  }
}
