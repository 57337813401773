<app-auth-layout [loading]="loading">
    <section class="h-full p-8 md:p-12">
        <h1 class="font-bold py- text-center text-lg pb-4 uppercase md:text-xl md:pb-8 lg:text-2xl">
            Email Verification
        </h1>

        <div class="text-center">
            <div *ngIf="loading; else error">
                <div class="text-primary py-12 animate-spin text-7xl duration-[5000s] material-icons">
                    autorenew
                </div>
                <div class="py-8 text-lg">Verifying...</div>
            </div>

            <div *ngIf="verified">
                <img [src]="'/assets/images/success.png'" class="h-auto mx-auto h-40" />
                <div class="py-8 text-lg">{{successMessage}}</div>
            </div>

            <ng-template #error>
                <app-no-content *ngIf="!verified" icon="/assets/images/error-message.png" [caption]="errorMessage">
                </app-no-content>
            </ng-template>
        </div>
    </section>
</app-auth-layout>