import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-payment-receipt",
  templateUrl: "./payment-receipt.component.html",
})
export class PaymentReceiptComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
