<app-dashboard-layout>
  <section class="flex-col flex w-full gap-8 md:flex-row">
    <div class="bg-white border rounded-lg flex-groww-full p-2 py-8 lg:w-4/5">
      <div class="h-48 text-center">[PDF HERE]</div>
    </div>

    <div class="lg:w-1/5">
      <button
        class="rounded font-medium bg-primary/90 text-white text-sm py-2 px-4 uppercase whitespace-nowrap md:px-8 hover:bg-primary"
      >
        Download
      </button>
    </div>
  </section>
</app-dashboard-layout>
