export const environment = {
  production: true,
  BASE_URL: "https://studentportal.fss-oyo.edu.ng:7000",
  FRONTEND_BASE_URL: "https://studentportal.fss-oyo.edu.ng",

  logo: "/assets/logos/ffs-oyo.png",
  short_name: "FSS-OYO",
  long_name: "Federal School of Surveying, Oyo",

  /**
   * Navigation names includes:
   * ["home", "bio-data", "payment", "course", "result", "appointment", "election", "accommodation", "application"]
   */

  //nav_links: ["home", "bio-data", "payment", "course", "result"],

  nav_links: {
    home: true,
    biodata: true,
    payment: true,
    course: true,
    result: true,
    appointment: false,
    election: false,
    accommodation: false,
    application: true,
  },

  remita: true,
};
