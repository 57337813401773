<main
  class="bg-gradient-to-t flex min-h-screen bg-opacity-50 from-primary/20 to-primary/5 w-full items-center justify-center md:p-4"
>
  <section
    class="rounded-md shadow-lg w-full overflow-hidden lg:max-w-screen-lg"
  >
    <app-loading-indicator
      *ngIf="loading"
      position="relative -mb-2"
    ></app-loading-indicator>

    <div class="rounded-md w-full justify-center overflow-hidden md:flex">
      <aside
        class="bg-gray-50 bg-opacity-50 text-primary p-8 hidden justify-center items-center md:flex md:w-1/3 lg:w-1/2"
      >
        <div
          class="bg-contain bg-center bg-no-repeat bg-opacity-50 h-64 text-white w-64"
          [style]="'background-image: url(' + logo + ')'"
        ></div>
      </aside>

      <section class="bg-white flex-grow h-screen overflow-y-auto md:h-full">
        <ng-content></ng-content>
      </section>
    </div>
  </section>
</main>
