import { HttpClient } from "@angular/common/http";
import { errorHandler } from "src/app/util/errorHandler";
import { authData } from "src/app/util/auth";
import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-accommodation-application-index",
  templateUrl: "./accommodation-application-index.component.html",
})
export class AccommodationApplicationIndexComponent implements OnInit {
  accommodation: any;
  constructor(private httpClient: HttpClient, private router: Router) {}

  hall = "";
  halls: Halls[] = [];

  loading = false;
  refreshing = true;
  notification = {};

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const message: string = location.hash.slice(1);

    if (message)
      this.notification = {
        message: message.replace(/\+/g, " "),
      };

      //Redirect to instructions page
      if (this.accommodation.AccommodationInstructions) {
        this.router.navigateByUrl(`/accommodation/instructions`);
        return;
      }

    const baseUrl = `${environment.BASE_URL}/accomodation/accomodationHalls`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.halls = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
      },
      error: (err: any) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.refreshing = false;
      },
    });
  }

  selectHall() {
    this.router.navigateByUrl(`/accommodation/application/${this.hall}`);
  }
}

interface Halls {
  HallName: string;
  HallOfResidenceId: string;
}
