import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-no-content",
  templateUrl: "./no-content.component.html",
})
export class NoContentComponent implements OnInit {
  constructor() {}

  @Input() icon?: string = "";
  @Input() caption = "Nothing to show";

  ngOnInit(): void {}
}
