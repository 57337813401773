import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-payment-generate",
  templateUrl: "./payment-generate.component.html",
})
export class PaymentGenerateComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  session = "";
  sessions: any = [];
  notification: any = {};
  noContentFound: boolean = false;

  loading = true;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/payments/sessionList`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        this.sessions = res.Data;
        this.loading = false;
        this.skeletonLoader = false;
      },
      (err) => {
        this.loading = false;
        this.skeletonLoader = false;
        this.noContentFound = err.status === 404;
        this.notification = errorHandler(err);
      }
    );
  }

  generateFees() {
    if (!this.session || this.loading) return;

    this.loading = true;
    this.notification = {};

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData().Token,
      }),
    };

    const baseUrl = `${environment.BASE_URL}/payments/generatefees`;
    this.httpClient.post(baseUrl, `"${this.session}"`, httpOptions).subscribe(
      (res: any) => {
        this.loading = false;
        this.router.navigateByUrl(`/payments#${res.Data || 0}+Fees+generated+for+${this.session}`);
      },
      (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      }
    );
  }
}
