import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { authData } from "src/app/util/auth";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-news-index",
  templateUrl: "./news-index.component.html",
})
export class NewsIndexComponent implements OnInit {
  constructor(private httpClient: HttpClient) {}

  loading = false;
  refreshing = true;
  _auth = authData();
  news: News[] | any = [];

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._auth.Token,
    }),
  };

  ngOnInit(): void {
    this.refreshing = true;
    const baseUrl = `${environment.BASE_URL}/users/allNews`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        this.news = res.Data;
        this.news.ModifiedDateTime = new Date(
          this.news.ModifiedDateTime
        ).toDateString();
      },
      complete: () => {
        this.refreshing = false;
      },
    });
  }

  formatDate(date: string) {
    return new Date(date).toDateString();
  }

  compareDate(date: string) {
    return +new Date() > +new Date(date) ? "Expired" : "Ongoing";
  }
}

interface News {
  Id: string;
  Body: string;
  Subject: string;
  ModifiedDateTime: string;
  ShortDescription: string;
}
