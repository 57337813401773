<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <section class="bg-white border rounded-md p-4">
        <header class="border-b flex text-lg w-full pb-6 gap-8 md:text-xl">
            BANK ACCOUNT INFORMATION
        </header>

        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <form ngNativeValidate *ngIf="!skeletonLoader" id="referral-application"
            class="max-w-screen-sm grid gap-8 grid-cols-1 " (ngSubmit)="submitForm()">
            <app-notification-alert class="pt-4" [data]="notification"></app-notification-alert>
            <div class="grid gap-8 grid-cols-1">
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Bank:
                    </div>
                    <div class="py-8">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select [(ngModel)]="bank" class="w-full p-2 focus:outline-none" name="bank">
                                <option disabled value="">Select a Bank</option>
                                <option *ngFor="let bank of result" value="{{bank.BankID}}">
                                    {{ bank.Name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Account Name:
                    </div>
                    <input required type="text" name="accountName" [(ngModel)]="payload.AccountName"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Account Number:
                    </div>
                    <input required type="number" name="accountNumber" [(ngModel)]="payload.AccountNumber"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Sort Code:
                    </div>
                    <input type="number" name="sortCode" [(ngModel)]="payload.SortCode"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
            </div>
            <nav class="flex flex-col py-2 gap-4 justify-center">
                <app-button-primary type="submit" [disabled]="loading" class="mx-auto max-w-screen-sm w-full">
                    Submit
                </app-button-primary>
            </nav>
        </form>
    </section>
</app-dashboard-layout>