import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
})
export class AuthLayoutComponent implements OnInit {
  constructor() {}

  @Input() loading: boolean = false;

  logo = environment.logo;

  ngOnInit(): void {}
}
