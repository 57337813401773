<section class="bg-white border rounded-lg mb-8 overflow-x-auto">
  <table class="font-mono text-sm w-full table">
    <thead>
      <tr>
        <th class="border-b text-sm text-left text-white p-4">Days</th>
        <th class="border-b text-left p-4 whitespace-nowrap" *ngFor="let time of [1, 2, 3, 4, 5]">
          Time {{ time }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="
          let day of [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Weekend'
          ]
        ">
        <td class="border-b p-2">{{ day }}</td>
        <td class="border-b p-4" *ngFor="let course of ['', '', '', '', '']">
          -
        </td>
      </tr>
    </tbody>
  </table>
</section>