import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
})
export class DashboardLayoutComponent implements OnInit {
  constructor() {}

  @Input() loading: boolean = false;
  @Input() notDashboard: boolean = false;
  @Input() downloader: Downloader | null = null;

  ngOnInit(): void {}
}

interface Downloader {
  url?: string;
  loading?: boolean;
  notDashboard?: boolean;
}
