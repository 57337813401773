import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-results-index",
  templateUrl: "./results-index.component.html",
})
export class ResultsIndexComponent implements OnInit {
  constructor() {}

  links = [
    {
      title: "Print Results",
      slug: "/results/print",
      icon: "/assets/icons/printer.png",
      text: "Add a line or two here that explains what this card does",
    },
  ];

  ngOnInit(): void {}
}
