<div class="flex flex-col pb-4 gap-4">
  <section
    class="bg-gradient-to-tr from-primary rounded-md to-primary/60 text-white p-4 lg:p-6"
  >
    <div class="font-black text-lg md:text-xl">
      {{ _auth?.Student.FirstName }} {{ _auth?.Student.LastName }}
    </div>

    <div
      class="flex flex-col gap-8 justify-between md:flex-row md:gap-4 md:items-end"
    >
      <div class="pt-4">
        <div class="font-bold text-sm pb-1">{{ _auth?.ProgrammeName }}</div>
        <div class="text-xs">{{ _auth?.Department }}</div>
      </div>

      <div class="flex gap-4">
        <div *ngFor="let item of items" class="text-center">
          <div class="font-bold text-sm pb-1 capitalize">{{ item.title }}</div>
          <div class="text-sm">{{ item.value }}</div>
        </div>
      </div>
    </div>
  </section>

  <section
    *ngIf="_auth.NewMatricNumber"
    class="border-success rounded flex bg-success/10 border-l-4 text-success p-3 items-center justify-between"
    role="alert"
  >
    <p class="max-w-screen-md text-sm">
      Your new Matriculation number is:
      <strong>{{ _auth.NewMatricNumber }}</strong>
    </p>

    <button
      title="Close"
      (click)="clearMessage()"
      class="rounded p-1 px-2 hover:bg-success/20"
    >
      &times;
    </button>
  </section>
</div>
