import { HttpClient, HttpHeaders } from "@angular/common/http";
import { authData, saveAuth } from "src/app/util/auth";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-student-info-header",
  templateUrl: "./student-info-header.component.html",
})
export class StudentInfoHeaderComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  _auth = authData();
  _courses = authData("_courses");

  items = [
    {
      title: this._auth?.Level,
      value: "Year",
    },
    {
      title: this._auth?.Semester,
      value: "Semester",
    },
    {
      title: this._courses?.Courses?.length || 0,
      value: "Courses",
    },
    {
      title:
        this._courses?.Courses?.reduce(
          (x: number, y: any) => x + +y?.CourseUnits,
          0
        ) || 0,
      value: "Units",
    },
  ];

  ngOnInit(): void {
    this.fetchCourse();
    this.fetchChecks();
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${authData().Token}`,
    }),
  };

  clearMessage() {
    const _auth = authData();
    saveAuth({ ..._auth, NewMatricNumber: null });
    this._auth = authData();
  }

  fetchCourse() {
    const baseUrl = `${environment.BASE_URL}/courses/currentCourses`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => saveAuth(res?.Data || [], "_courses"),
      error: (err) => saveAuth([], "_courses"),
    });
  }

  fetchChecks() {
    const baseUrl = `${environment.BASE_URL}/users/studentChecks`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => saveAuth(res.Data, "_checks"),
      error: (err) => saveAuth({}, "_checks"),
    });
  }
}
