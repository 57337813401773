import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { authData } from 'src/app/util/auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accommodation-instructions',
  templateUrl: './accommodation-instructions.component.html',
  styleUrls: ['./accommodation-instructions.component.scss']
})
export class AccommodationInstructionsComponent implements OnInit {
ProgrammeTypeAccomodationInstructions: any;
HallInstructions: any;
acknowledged = false;
notification = {};

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    //Load Accomodation Instructions
    await this.loadAccomodationInstructions();
  }

  loadAccomodationInstructions() {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData().Token,
      }),
    };
    const baseUrl = `${environment.BASE_URL}/accomodation/accommodationInstructions`;
    this.httpClient.get(baseUrl, httpOptions)
      .subscribe((response: any) => {
        this.ProgrammeTypeAccomodationInstructions = response.Data;        
      });
  }

  toggleAcknowledgment(event: Event) {
    this.acknowledged = (event.target as HTMLInputElement).checked;
  }
  acknowledgeInstructions() {
    this.acknowledged = true;
    this.router.navigateByUrl(`/accommodation/application`);
  }
  back() {
    this.router.navigateByUrl(`/accommodation`);
  }

}
