<button
  [type]="type"
  [disabled]="disabled"
  [class]="
    disabled
      ? 'bg-gray-400 rounded font-medium text-white text-sm py-2 px-4 md:px-8 uppercase whitespace-nowrap w-full'
      : outline
      ? 'bg-white border-gray-400 border text-primary rounded font-medium text-sm py-2 px-4 md:px-8 uppercase whitespace-nowrap w-full'
      : 'bg-primary/90 rounded font-medium text-white text-sm py-2 px-4 md:px-8 uppercase whitespace-nowrap hover:bg-primary w-full'
  "
>
  <div class="flex max-w-screen-sm w-full gap-4 items-center justify-center">
    <ng-content></ng-content>
  </div>
</button>
