<app-dashboard-layout
  [notDashboard]="true"
  [loading]="loading"
  [downloader]="downloader"
>
  <header class="text-lg mb-6 w-full pb-4 gap-8 md:text-xl">
    Documents Upload
    <div class="text-sm py-2 text-gray-600">
      Allowed Types: PDF, PNG, JPG, JPEG
    </div>
    <div class="text-sm text-gray-600">Max Size: 2mb</div>
  </header>

  <app-notification-alert [data]="notification"></app-notification-alert>

  <section *ngIf="refreshing">
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div
      class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"
    ></div>
  </section>

  <section *ngIf="!refreshing">
    <app-no-content *ngIf="!documents.length" caption="No Documents to show">
    </app-no-content>

    <section *ngIf="documents.length" class="flex flex-col pb-8 gap-4">
      <section
        *ngFor="let document of documents; let index = index"
        class="bg-white rounded-md border-2 p-4 block group md:p-6 hover:border-primary/60"
      >
        <div
          class="font-medium text-base pb-1 capitalize group-hover:text-primary"
        >
          {{ document.DocumentType }}
        </div>
        <div class="text-xs pb-6 text-gray-500">
          {{ document.Compulsory ? "Compulsory" : "Optional" }}
        </div>

        <nav
          *ngIf="document?.FileAttachment?.Data; else uploadFile"
          class="rounded flex gap-8 justify-between items-center"
        >
          <button
            (click)="toggleDialog(index)"
            class="bg-transparent rounded font-medium bg-error/5 text-error text-sm text-center py-2 px-4 inline-block uppercase whitespace-nowrap md:px-8 hover:bg-error/90 hover:text-white"
          >
            DELETE
          </button>

          <app-button-primary
            [disabled]="loading"
            (click)="previewDocument(document.FileAttachment)"
          >
            PREVIEW
          </app-button-primary>
        </nav>

        <ng-template #uploadFile>
          <nav
            class="border rounded flex bg-primary/20 border-primary/30 p-1 gap-8 justify-between items-center"
          >
            <input
              type="file"
              [id]="index"
              (change)="uploadDocument($event, index, document)"
              class="text-xs text-sm w-full text-gray-700 file:rounded-full file:font-medium file:text-primary file:bg-white/80 file:mr-4 file:py-2 file:px-4 file:border-0 hover:file:bg-white"
            />

            <app-button-primary
              [disabled]="
                loading ||
                !payload[index]?.FileAttachment?.Data ||
                payload[index]?.FileAttachment?.Length > MAX_SIZE
              "
              (click)="saveDocument(index)"
            >
              Save file
            </app-button-primary>
          </nav>

          <div
            *ngIf="payload[index]?.FileAttachment?.Length > MAX_SIZE"
            class="text-xs py-1 text-error/80"
          >
            File size exceed 2mb
          </div>
        </ng-template>
      </section>

      <!-- <div *ngIf="documents.length" class="flex justify-center">
        <app-button-primary
          (click)="uploadDocument()"
          [disabled]="loading"
          class="max-w-sm w-full"
        >
          Submit Documents
        </app-button-primary>
      </div> -->
    </section>
  </section>
</app-dashboard-layout>

<section
  role="dialog"
  aria-modal="true"
  *ngIf="deleteDialog"
  aria-labelledby="modal-title"
  class="inset-0 z-10 fixed overflow-y-auto"
>
  <div class="flex flex-col h-full text-center p-4 items-center justify-center">
    <div
      aria-hidden="true"
      class="bg-gray-500 bg-opacity-75 inset-0 transition-opacity fixed"
    ></div>

    <div
      class="bg-white rounded-lg shadow-xl text-left w-full transform transition-all overflow-hidden sm:max-w-screen-sm sm:my-8"
    >
      <app-loading-indicator *ngIf="loading"></app-loading-indicator>

      <section class="p-8">
        <div class="text-center p-4">Please confirm your action</div>

        <nav class="grid pt-4 gap-4 grid-cols-2">
          <button
            (click)="toggleDialog(-1)"
            class="bg-white border border-error rounded font-medium text-error text-sm text-center py-2 px-4 block uppercase whitespace-nowrap md:px-8 hover:bg-error/90 hover:text-white"
          >
            Cancel
          </button>

          <button
            (click)="deleteDocument()"
            class="bg-primary rounded font-medium text-white text-sm text-center py-2 px-4 block uppercase whitespace-nowrap md:px-8 hover:bg-primary/90"
          >
            Continue
          </button>
        </nav>
      </section>
    </div>
  </div>
</section>
