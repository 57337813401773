import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData, removeAuth } from "src/app/util/auth";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-account-dropdown",
  templateUrl: "./account-dropdown.component.html",
})
export class AccountDropdownComponent implements OnInit {
  constructor(private router: Router, private httpClient: HttpClient) {}

  _auth = authData();
  links = [
    { title: "News & Updates", slug: "/news", icon: "newspaper" },
    { title: "Support", slug: "/complaints", icon: "support_agent" },
  ];

  loading = false;
  notification = {};
  PassportData = "";

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/users/passport`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.PassportData = res.Data.PassportData;
        }
      },
    });
  }

  logoutUser(): void {
    removeAuth();
    this.router.navigateByUrl("/login");
  }
}
