import { errorHandler } from "src/app/util/errorHandler";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-election-vote",
  templateUrl: "./election-vote.component.html",
})
export class ElectionVoteComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient
  ) {}

  loading = false;
  refreshing = true;
  _auth = authData();
  notification = {};
  payload: any = [];
  electionName = "";
  positions: any[] | any = [];

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._auth.Token,
    }),
  };

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.refreshing = true;
      this.electionName = params.id;

      const baseUrl = `${environment.BASE_URL}/elections/allElections?electionName=${params.id}`;
      this.httpClient
        .get(baseUrl, this.httpOptions)
        .subscribe({
          next: (res: any) => {
            if (res.Success) {
              this.positions = res.Data;
            } else {
              this.notification = errorHandler({
                error: true,
                message: res.Message,
              });
            }
          },
        })
        .add(() => {
          this.refreshing = false;
        });
    });
  }

  selectCandidate(position: number, candidate: number) {
    this.payload = { ...this.payload, [position]: candidate };
  }

  submitVotes() {
    this.loading = true;

    const payload = {
      ElectionName: this.electionName,
      CandidateIds: [...Object.values(this.payload)],
    };

    const baseUrl = `${environment.BASE_URL}/elections/vote`;
    this.httpClient
      .post(baseUrl, payload, this.httpOptions)
      .subscribe({
        next: (res: any) => {
          if (res.Success) {
            this.router.navigateByUrl("election#Thanks+For+Voting");
          } else {
            this.notification = errorHandler({
              error: true,
              message: res.Message,
            });
          }
        },
        error: (err) => {
          this.notification = errorHandler(err);
        },
      })
      .add(() => {
        this.loading = false;
      });
  }
}
