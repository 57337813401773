import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { authData } from "src/app/util/auth";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-news-details",
  templateUrl: "./news-details.component.html",
})
export class NewsDetailsComponent implements OnInit {
  constructor(private route: ActivatedRoute, private httpClient: HttpClient) {}

  loading = false;
  refreshing = true;
  _auth = authData();
  news: News[] | any = [];

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._auth.Token,
    }),
  };

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.refreshing = true;
      const baseUrl = `${environment.BASE_URL}/users/news/${params.id}`;

      this.httpClient.get(baseUrl, this.httpOptions).subscribe({
        next: (res: any) => {
          this.news = res.Data;
          this.news.ModifiedDateTime = new Date(
            this.news.ModifiedDateTime
          ).toDateString();
        },
        complete: () => {
          this.refreshing = false;
        },
      });
    });
  }

  formatDate(date: string) {
    return new Date(date).toDateString();
  }
}

interface News {
  Id: string;
  Body: string;
  Subject: string;
  ModifiedDateTime: string;
  ShortDescription: string;
}
