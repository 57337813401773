import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dialog-overlay",
  templateUrl: "./dialog-overlay.component.html",
})
export class DialogOverlayComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
