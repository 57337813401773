import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-complaints-submit",
  templateUrl: "./application-complaints-submit.component.html",
  styleUrls: ["./application-complaints-submit.component.scss"],
})
export class ApplicationComplaintsSubmitComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  _auth = authData();

  payload: any = {};
  complaintAttachment: any = {};

  complaint = "";
  semester = "";
  reason = "";
  response = {};

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/complaints/complainttypes`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
  }
  submitForm() {
    this.loading = true;
    this.notification = {};

    this.response = {
      ComplaintDescription: this.reason,
      ComplaintType: this.complaint,
      ComplaintAttachment: this.complaintAttachment == '' ? this.complaintAttachment : null,
    };

    const baseUrl = `${environment.BASE_URL}/complaints/savecomplaint`;
    this.httpClient.post(baseUrl, this.response, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.router.navigateByUrl(
            "/application/complaints#Completed+Successfully"
          );
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }
  uploadDocument(event: any) {
    const file = event.target.files[0];
    const extension = file.type.substr(file.type.lastIndexOf("/") + 1);

    if (event.target.files && file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.complaintAttachment = {
            //Length: file.size,
            FileData: event.target.result.split("base64,")[1],
            FileName: file.name,
            FileType: extension,
          };
      };
      reader.readAsDataURL(file);
    }
  }
}
