<app-auth-layout [loading]="loading">
  <form
    ngNativeValidate
    (ngSubmit)="resetPassword()"
    class="flex flex-col h-full p-8 md:p-12"
  >
    <h1
      class="font-bold py- text-center text-lg pb-4 uppercase md:text-xl md:pb-8 lg:text-2xl"
    >
      Forgot Password
    </h1>

    <app-notification-alert [data]="notification"></app-notification-alert>

    <div class="py-4">
      <label
        class="font-bold text-grey-darker text-sm mb-2 block"
        htmlFor="email"
      >
        Matric number
      </label>
      <input
        required
        id="MatricNo"
        type="text"
        [(ngModel)]="MatricNo"
        placeholder="Matric number"
        class="border rounded bg-gray-50 text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
      />
    </div>

    <div class="text-right">
      <a
        routerLink="/login"
        class="font-bold text-sm text-primary inline-block align-baseline hover:text-primary-dark hover:underline"
      >
        Login?
      </a>
    </div>

    <div class="py-8">
      <app-button-primary type="submit" [disabled]="loading" class="w-full">
        Reset password
      </app-button-primary>
    </div>
  </form>
</app-auth-layout>
