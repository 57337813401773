import { Router } from "@angular/router";
import { environment } from "./../../../../environments/environment";
import { authData } from "src/app/util/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-courses-history-page",
  templateUrl: "./courses-history-page.component.html",
})
export class CoursesHistoryPageComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  sessions: any = [];
  selectedSession: any = "";

  loading = true;
  notification: any = {};
  noContentFound: boolean = false;

  ngOnInit(): void {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData().Token,
      }),
    };

    const baseUrl = `${environment.BASE_URL}/courses/coursehistory`;
    this.httpClient.get(baseUrl, httpOptions).subscribe(
      (res: any) => {
        this.loading = false;
        const courses = res.Data;
        const sessions: any = {};

        courses.forEach((course: any) => {
          if (!sessions[course.Session])
            sessions[course.Session] = { name: course.Session, courses: [] };
          sessions[course.Session].courses.push(course);
        });

        this.sessions = Object.values(sessions);
        this.selectedSession = this.sessions[0].name;
      },
      (err) => {
        this.loading = false;
        this.noContentFound = err.status === 404;
        this.notification = {
          error: true,
          message:
            err?.error?.Message || "An error occurred. Please, try again",
        };
      }
    );
  }

  getTotalUnits = (courses: any) =>
    courses.reduce(
      (previousValue: any, currentValue: any) =>
        previousValue + +currentValue.CourseUnits,
      0
    );

  accordionClicked(selected: string) {
    this.selectedSession = selected;
  }
}
