import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-dashboard-card",
  templateUrl: "./dashboard-card.component.html",
})
export class DashboardCardComponent implements OnInit {
  constructor() {}

  @Input() icon: string = "";
  @Input() title: string = "";
  @Input() caption: string = "";

  ngOnInit(): void {}
}
