<section
  role="dialog"
  aria-modal="true"
  aria-labelledby="modal-title"
  class="inset-0 z-10 fixed overflow-y-auto"
>
  <div class="flex h-screen text-center p-4 items-center justify-center">
    <div
      aria-hidden="true"
      class="bg-gray-500 bg-opacity-75 inset-0 transition-opacity fixed"
    ></div>

    <div
      class="bg-white rounded-lg shadow-xl text-left w-full max-h-5/6 p-8 transform transition-all relative inline-block align-bottom overflow-hidden sm:max-w-screen-sm sm:my-8"
    >
      <ng-content></ng-content>
    </div>
  </div>
</section>
