<app-dashboard-layout [loading]="loading" [downloader]="downloader">
  <app-notification-alert [data]="notification"></app-notification-alert>

  <section
    class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4"
  >
    <app-course-registration-form>
      <app-dashboard-card
        title="Register Courses"
        icon="/assets/icons/homework.png"
        caption="Add a line or two here that explains what this card does"
      >
      </app-dashboard-card>
    </app-course-registration-form>

    <app-dashboard-card
      *ngFor="let link of links"
      [routerLink]="link.slug"
      [title]="link.title"
      [icon]="link.icon"
      [caption]="link.text"
    ></app-dashboard-card>

    <app-dashboard-card
      (click)="printExamDocket()"
      title="Print Exam Docket"
      icon="/assets/icons/printer.png"
      caption="Add a line or two here that explains what this card does"
    ></app-dashboard-card>
  </section>
</app-dashboard-layout>
