<app-dashboard-layout [notDashboard]="true" [loading]="loading || refreshing">
    <section class="bg-white border rounded-md p-4">
        <header class="border-b flex text-lg w-full pb-6 gap-8 md:text-xl">
            Referral Application
        </header>

        <div *ngIf="refreshing" class="max-w-screen-md grid pb-4 gap-4 grid-cols-1 md:gap-8 md:grid-cols-2">
            <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]" class="border rounded-md bg-gray-300 h-20 animate-pulse">
            </div>
        </div>

        <form ngNativeValidate *ngIf="!refreshing" id="referral-application"
            class="max-w-screen-sm grid gap-8 grid-cols-1 " (ngSubmit)="submitForm()">
            <app-notification-alert class="pt-4" [data]="notification"></app-notification-alert>

            <div class="grid gap-8 grid-cols-1">
                <label class="block" *ngIf="form.unit">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Number Of Units To Be Taken
                    </div>
                    <input required type="number" name="units" [(ngModel)]="payload.NumberOfUnitsToBeTaken"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>

                <label class="block" *ngIf="form.course">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Number Of Courses To Be Taken
                    </div>
                    <input required type="number" name="courses" [(ngModel)]="payload.NumberOfCoursesToBeTaken"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
            </div>

            <nav class="flex flex-col py-6 gap-4 justify-center">
                <app-button-primary type="submit" [disabled]="loading" class="mx-auto max-w-screen-sm w-full">
                    Submit
                </app-button-primary>
            </nav>
        </form>
    </section>
</app-dashboard-layout>