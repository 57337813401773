import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-button-primary",
  templateUrl: "./button-primary.component.html",
})
export class ButtonPrimaryComponent implements OnInit {
  constructor() {}

  @Input() type: string = "button";
  @Input() outline: boolean = false;
  @Input() disabled: boolean = false;

  ngOnInit(): void {}
}
