<app-dashboard-layout>
    <app-notification-alert [data]="notification"></app-notification-alert>

    <form class="flex flex-col mx-auto max-w-screen-md p-4 z-20 justify-center">
        <h1 class="font-medium text-center text-lg">
            Select a hall to apply for accommodation
        </h1>

        <section *ngIf="refreshing">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <section *ngIf="!refreshing">
            <div class="py-8">
                <div class="bg-white border border-primary rounded-lg w-full p-2">
                    <select name="hall" [(ngModel)]="hall" class="w-full p-2 focus:outline-none">
                        <option disabled value="">Select a hall</option>
                        <option *ngFor="let hall of halls" [value]='hall.HallOfResidenceId'>{{ hall.HallName }}</option>
                    </select>
                </div>
            </div>

            <app-button-primary class="w-full" (click)="selectHall()" [disabled]="refreshing || loading">
                Continue
            </app-button-primary>
        </section>
    </form>
</app-dashboard-layout>