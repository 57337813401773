<div
  class="bg-white rounded-md cursor-pointer h-full border-2 text-left p-6 transform duration-200 group hover:border-primary hover:scale-105">
  <ng-content></ng-content>

  <img [src]="icon" class="rounded-full h-16 w-16" alt="" />
  <div class="font-medium text-sm pt-2 pb-1 capitalize md:text-lg">
    {{ title }}
  </div>
  <div class="text-xs text-gray-500">
  </div>
</div>