import { authData } from "src/app/util/auth";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { errorHandler } from "src/app/util/errorHandler";

@Component({
  selector: "app-application-index",
  templateUrl: "./application-index.component.html",
})
export class ApplicationIndexComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};
  notification = {};
  downloader: any = null;

  fakeLoader = false;
  loading = false;
  StudentProfileView = false;

  links: Link[] = [];

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const {
      CanViewTranscript,
      CatchUp,
      HasExtraYear,
      CatchUpApplicationType,
      ReferralApplicationType,
      StudentProfileViewAllowed,
      DefermentAllowed,
      BankAccountInfoAllowed,
    } = authData("_checks");

    this.StudentProfileView = StudentProfileViewAllowed;

    if (HasExtraYear && ReferralApplicationType > 0)
      this.links.push({
        title: "Referral Application",
        slug: "/application/referral",
        icon: "/assets/icons/homework.png",
        text: "Add a line or two here that explains what this card does",
      });

    if (CatchUp && CatchUpApplicationType > 0)
      this.links.push({
        title: "Supplementary Application",
        slug: "/application/catchup",
        icon: "/assets/icons/homework.png",
        text: "Add a line or two here that explains what this card does",
      });

    if (CanViewTranscript)
      this.links.push({
        title: "Student Academic Transcript",
        slug: "/application/transcript",
        icon: "/assets/icons/document.png",
        text: "Add a line or two here that explains what this card does",
      });

    if (DefermentAllowed)
      this.links.push({
        title: "Deferment Application",
        slug: "/application/deferment",
        icon: "/assets/icons/ballot-box.png",
        text: "Add a line or two here that explains what this card does",
      });

    if (BankAccountInfoAllowed)
      this.links.push({
        title: "Bank Account Information",
        slug: "/application/bankinfo",
        icon: "/assets/icons/printer.svg",
        text: "Add a line or two here that explains what this card does",
      });

    this.links.push({
      title: "Complaints",
      slug: "/application/complaints",
      icon: "/assets/icons/homework.png",
      text: "Add a line or two here that explains what this card does",
    });

    const message: string = location.hash.slice(1);
    if (message) {
      this.notification = {
        message: message.replace(/\+/g, " "),
      };
    }
  }
  printProfile() {
    if (this.loading) return;

    const _checks = authData("_checks");
    const { StudentProfileViewAllowed } = _checks;
    if (!StudentProfileViewAllowed) {
      this.notification = {
        message: "You are not allowed to print your student profile",
        error: true,
      };
      return;
    }

    this.loading = true;
    this.notification = {};
    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/users/studentprofile`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.downloader = {
            loading: false,
            name: res.Data.Name,
            url: `data:application/octet-stream;base64,${res.Data.Data}`,
          };
        } else {
          this.downloader = null;
          this.notification = { error: true, message: res.Message };
          this.loading = false;
        }
      },
      error: (err) => {
        this.downloader = null;
        this.notification = errorHandler(err);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}

interface Link {
  title: string;
  slug: string;
  icon: string;
  text: string;
}
