import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { authData } from 'src/app/util/auth';
import { errorHandler } from 'src/app/util/errorHandler';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-application-student-profile",
  templateUrl: "./application-student-profile.component.html",
  styleUrls: ["./application-student-profile.component.scss"],
})
export class ApplicationStudentProfileComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  downloader: any = null;
  notification = {};
  loading = false;
  skeletonLoader = true;
  
  ngOnInit(): void {
  }
}
