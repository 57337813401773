<app-dashboard-layout>
  <section class="bg-white border rounded-md p-4 md:p-8">
    <header class="flex pb-6 gap-4 justify-between">
      <div class="font-bold text-lg mdtext-lg">Course info</div>
      <button
        class="rounded font-medium bg-primary/90 text-white text-sm py-2 px-4 uppercase whitespace-nowrap md:px-8 hover:bg-primary"
      >
        ADD TO MY COURSES
      </button>
    </header>

    <div class="font-medium text-lg pb-4">Keyboarding and Wordprocessing</div>
    <div class="text-sm text-gray-500">BUS 201</div>

    <div class="max-w-screen-md text-sm py-6 text-gray-600">
      {{ courseId }} Learn about keyboarding, essential and advanced word
      processing, document formatting and design, and communication skills
      within a single text, along with a strong emphasis on honing skills by
      completing projects in a realistic office environment.
    </div>

    <div class="max-w-screen-md text-sm text-gray-600">
      Number of hours: 24<br />
      Number of units: 3<br />
      Practical sessions: Yes
    </div>
  </section>
</app-dashboard-layout>
