<app-dashboard-layout>
  <main>
    <div class="pt-20">
      <img
        src="https://www.google.com/images/errors/robot.png"
        class="mx-auto max-w-full"
      />
      <div class="text-center pt-8">Page under construction</div>
    </div>
  </main>
</app-dashboard-layout>
