import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-complaints",
  templateUrl: "./application-complaints.component.html",
  styleUrls: ["./application-complaints.component.scss"],
})
export class ApplicationComplaintsComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};
  notification = {};
  downloader: any = null;

  fakeLoader = false;
  loading = false;
  StudentProfileView = false;

  links: Link[] = [];

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    this.links.push({
      title: "Submit Complaint",
      slug: "/application/complaints/submit",
      icon: "/assets/icons/ballot-box.png",
      text: "Add a line or two here that explains what this card does",
    });

    this.links.push({
      title: "View all Complaint",
      slug: "/application/complaints/view",
      icon: "/assets/icons/visa.png",
      text: "Add a line or two here that explains what this card does",
    });

    const message: string = location.hash.slice(1);
    if (message) {
      this.notification = {
        message: message.replace(/\+/g, " "),
      };
    }
  }
}

interface Link {
  title: string;
  slug: string;
  icon: string;
  text: string;
}
