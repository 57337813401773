<app-auth-layout [loading]="loading">
  <section class="h-full p-8 md:p-12">
    <h1
      class="font-bold py- text-center text-lg pb-4 uppercase md:text-xl md:pb-8 lg:text-2xl"
    >
      Admin Login
    </h1>

    <div class="text-center">
      <div *ngIf="loading; else error">
        <div
          class="text-primary py-12 animate-spin text-7xl duration-[5000s] material-icons"
        >
          hourglass_top
        </div>
        <div class="py-8">Please wait.</div>
      </div>

      <ng-template #error>
        <app-no-content
          icon="/assets/images/error-message.png"
          [caption]="errorMessage"
        ></app-no-content>
      </ng-template>
    </div>
  </section>
</app-auth-layout>
