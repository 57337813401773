import { HttpClient, HttpHeaders } from "@angular/common/http";
import { authData } from "src/app/util/auth";
import { Component, OnInit } from "@angular/core";
import { environment } from "./../../../../environments/environment";
import { errorHandler } from "src/app/util/errorHandler";
import { Router } from "@angular/router";

@Component({
  selector: "app-document-upload-page",
  templateUrl: "./document-upload-page.component.html",
})
export class DocumentUploadPageComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  MAX_SIZE = 2097152;
  url: any = null;
  _auth = authData();
  loading = false;
  refreshing = true;

  deleteDialog = false;
  itemToDelete = "";

  payload: any = {};
  notification = {};
  downloader: any = null;
  status: boolean = false;

  documents: any = {
    DocumentUploadedDTOs: [],
    Confirmation: true,
  };

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/bioData/documentUploaded`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        this.refreshing = false;
        this.documents = res.Data.DocumentUploadedDTOs;
      },
      error: (err) => {
        this.refreshing = false;
      },
    });
  }

  uploadDocument(event: any, index: number, document: any) {
    const file = event.target.files[0];
    const extension = file.type.substr(file.type.lastIndexOf("/") + 1);

    if (event.target.files && file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.payload[index] = {
          ...document,
          FileAttachment: {
            Length: file.size,
            Data: event.target.result.split("base64,")[1],
            Name: document.DocumentTypeName + "." + extension,
            Type: extension,
          },
        };
      };
      reader.readAsDataURL(file);
    }
  }

  saveDocument(index: number) {
    const item = this.payload[index];
    if (!item) return;
    this.loading = true;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData().Token,
      }),
    };

    const payload = {
      Confirmation: false,
      DocumentUploadedDTOs: [{ ...this.payload[index] }],
    };

    const baseUrl = `${environment.BASE_URL}/bioData/uploadDocuments`;
    this.httpClient.post(baseUrl, payload, httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.notification = {
            error: false,
            message: "Document Uploaded Successfully",
          };
          this.documents[index] = { ...item };
        } else {
          this.notification = {
            error: true,
            message: res.Message || "An error occurred",
          };
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }

  previewDocument(data: any) {
    if (this.loading) return;
    const extension = data.Type;

    this.downloader = {
      loading: false,
      name: data?.Name,
      url: `${
        extension == "pdf"
          ? "data:application/octet-stream;base64,"
          : `data:image/${extension};base64,`
      }${data.Data}`,
    };
  }

  toggleDialog(index: number) {
    const status = index >= 0;
    this.deleteDialog = status;

    if (index < 0) return;
    this.itemToDelete = this.documents[index].DocumentID;
  }

  deleteDocument() {
    this.loading = true;

    const baseUrl = `${environment.BASE_URL}/bioData/deleteDocumentUpload?documentId=${this.itemToDelete}`;
    this.httpClient.delete(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          location.reload();
        } else {
          this.notification = {
            error: true,
            message: res.Message || "An error occurred",
          };
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.loading = false;
        this.toggleDialog(-1);
      },
    });
  }
}
