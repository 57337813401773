<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <section class="bg-white border rounded-md p-4">
        <header class="border-b flex text-lg w-full pb-6 gap-8 md:text-xl">
            Fill a Complaint:
        </header>

        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <form ngNativeValidate *ngIf="!skeletonLoader" id="referral-application"
            class="max-w-screen-sm grid gap-8 grid-cols-1 " (ngSubmit)="submitForm()">
            <app-notification-alert class="pt-4" [data]="notification"></app-notification-alert>

            <div class="grid gap-8 grid-cols-1">
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Matriculation Number:
                    </div>
                    <label
                        class="rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light">
                        {{ _auth?.Matric }}</label>
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Phone:
                    </div>
                    <label
                        class="rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light">
                        {{ _auth?.Student.Mobile }}</label>
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Email:
                    </div>
                    <label
                        class="rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light">
                        {{ _auth?.Student.CommunicationEmail }}</label>
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Complaint Body:
                    </div>
                    <div class="py-8">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select [(ngModel)]="complaint" class="w-full p-2 focus:outline-none" name="complaint">
                                <option disabled value="">Select a complaint</option>
                                <option *ngFor="let complaint of payload" value="{{complaint.ComplaintTypeID}}">
                                    {{ complaint.ComplaintName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Complaint Body:
                    </div>
                    <textarea id="message" rows="4" [(ngModel)]="reason" [required]="true" class="block p-2.5 border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700
                        appearance-none focus:outline-none focus-within:border-primary-light"
                        placeholder="Your complaint here...">
                    </textarea>
                </label>
            </div>
            <nav class="border rounded flex bg-primary/20 border-primary/30 p-1 gap-8 justify-between items-center">
                <input type="file" (change)="uploadDocument($event)"
                    class="text-xs text-sm w-full text-gray-700 file:rounded-full file:font-medium file:text-primary file:bg-white/80 file:mr-4 file:py-2 file:px-4 file:border-0 hover:file:bg-white" />
            </nav>
            <nav class="flex flex-col py-2 gap-4 justify-center">
                <app-button-primary type="submit" [disabled]="loading" class="mx-auto max-w-screen-sm w-full">
                    Submit
                </app-button-primary>
            </nav>
        </form>
    </section>
</app-dashboard-layout>